import { getRawType } from "./index";

const storage = {
  /**
   * 获取缓存
   * @param {String} key  key
   * @param {String} def  若想要无缓存时，返回默认值则get('key','默认值')
   */
  get(key, def = "") {
    // 获取缓存
    const timeout = parseInt(localStorage.getItem(`${key}__separator__`) || 0);
    // 过期失效
    if (timeout) {
      if (Date.now() > timeout) {
        this.remove(key);
        return;
      }
    }
    let value = null;
    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (err) {
      value = localStorage.getItem(key);
    }
    return value || def;
  },
  set(key, value, timeout = 0) {
    /**
     * 设置缓存
     * @param {String} key       key
     * @param {Any} value     value
     * @param {Number} timeout   过期时间（单位：分钟）不设置时间即为永久保存
     */
    const _timeout = parseInt(timeout);
    const val =
      getRawType(value) === "object" || getRawType(value) === "array"
        ? JSON.stringify(value)
        : value;
    localStorage.setItem(key, val);
    if (_timeout) {
      localStorage.setItem(
        `${key}__separator__`,
        Date.now() + 1000 * 60 * _timeout
      );
    } else {
      localStorage.removeItem(`${key}__separator__`);
    }
    return value;
  },
  remove(key) {
    // 移除缓存
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}__separator__`);
    return undefined;
  }
};

export default storage;
