<template>
  <el-popover placement="top-start" title="" width="240" trigger="click">
    <div slot="reference">
      <slot></slot>
    </div>
    <div>
      <el-input
        size="small"
        v-model="keyword"
        suffix-icon="el-icon-search"
        placeholder="查找字段 按Enter键确认"
        @keyup.enter.native="handleSearch"
      ></el-input>
      <div class="tree-wrap">
        <template v-for="(group, index) of displayFields">
          <p
            class="group-title"
            v-if="typeof group === 'string' && group"
            :key="group"
          >
            {{ group }}
          </p>
          <!-- 数据长度不对的时候证明是搜索 不允许拖拽排序 -->
          <el-tree
            :key="group.prop"
            v-else-if="group.length"
            :data="group"
            node-key="prop"
            :draggable="
              displayFields.length === options.length &&
                group.length === options[index].length
            "
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            @node-drop="handleNodeDrop"
          >
            <span
              class="custom-tree-node"
              :class="{ disabled: data.disabled }"
              slot-scope="{ node, data }"
            >
              <i class="iconfont icon-drag-dot"></i>
              <span class="label">{{ node.label }}</span>
              <el-switch
                v-model="data.checked"
                :disabled="data.disabled"
                @change="handleSwitch(index, data)"
              ></el-switch>
            </span>
          </el-tree>
        </template>
      </div>
      <div class="actions">
        <el-button size="small" @click="reset">恢复默认</el-button>
        <el-button size="small" @click="openAll">显示所有</el-button>
      </div>
    </div>
  </el-popover>
</template>

<script>
import storage from "@/utils/storage";
export default {
  props: {
    // 保证全局唯一标识
    name: {
      type: String,
      default: "",
      required: true
    },
    // 默认选项
    // Array<{label:string;prop:string;checked:boolean;disabled:boolean}[]|string>
    // Array<{label:string;prop:string;checked:boolean;disabled:boolean}>
    options: {
      type: Array,
      default: () => [],
      required: true
    }
  },
  data() {
    return {
      // 关键字
      keyword: "",
      fields: [],
      displayFields: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    emit() {
      const checkedFields = this.fields.reduce((acc, cur) => {
        if (Array.isArray(cur)) {
          acc.push(...cur.filter(i => i.checked).map(i => i.prop));
        }
        return acc;
      }, []);
      // 储存设置字段内容的历史记录
      storage.set(`${this.name}TableFieldData`, this.displayFields);
      // 储存当前显示的字段
      storage.set(`${this.name}TableFields`, checkedFields);
      this.$emit("change", {
        checkedFieldData: this.fields,
        checkedFields
      });
    },
    // 初始化显示数据
    initData() {
      const historyFields = storage.get(`${this.name}TableFieldData`);
      const fields = this.$deepClone(historyFields || this.options);
      /**
       * 如果直接包含字符串 约定为分组title
       *如: ["报备信息", [{label: "客户姓名",...}..., "带看信息":[{label: "带看时间",..."}]]
       * 字符串后面的数组为字符串分组的字段值
       * 详细参考 带看信息的使用方式 如果 不需要分组 直接传入一个以为数组即可
       */
      const groupedFields = historyFields
        ? historyFields
        : fields.findIndex(f => typeof f === "string") > -1
        ? [...fields]
        : [fields];
      // 这里浅拷贝方便switch的时候改变displayFields的值的时候 同步更改fields 不用再去多写逻辑
      this.fields = [...groupedFields];
      this.displayFields = [...groupedFields];
      // 如果没有存过 默认将options存入缓存 并通知父级好处理table显示
      if (!historyFields) {
        this.emit();
      }
    },
    // 处理开关
    handleSwitch(groupIndex, item) {
      this.emit();
    },
    // 处理搜索
    handleSearch() {
      if (!this.keyword.trim()) {
        this.displayFields = [...this.fields];
        return;
      }
      this.displayFields = this.fields.map(group => {
        if (typeof group === "string") return "";
        return group.filter(field => {
          return field.label.includes(this.keyword.trim());
        });
      });
    },
    // 恢复默认
    reset() {
      this.keyword = "";
      const fields = this.$deepClone(this.options);
      const groupedFields =
        fields.findIndex(f => typeof f === "string") > -1
          ? [...fields]
          : [fields];
      this.fields = [...groupedFields];
      this.displayFields = [...groupedFields];
      this.emit();
    },
    // 打开所有开关
    openAll() {
      const fields = this.fields.map(group => {
        if (typeof group === "string") return group;
        return group.map(field => {
          return {
            ...field,
            checked: true
          };
        });
      });
      this.displayFields = this.$deepClone(fields);
      this.fields = [...this.displayFields];
      this.keyword = "";
      this.emit();
    },
    // 拖拽成功
    handleNodeDrop() {
      this.keyword = "";
      this.fields = [...this.displayFields];
      this.$nextTick(() => {
        this.emit();
      });
    },
    /**
     拖拽时判定目标节点能否被放置。type 参数有三种情况：
     'prev'、'inner' 和 'next'，
     分别表示放置在目标节点前、插入至目标节点和放置在目标节点后
     */
    allowDrop(draggingNode, dropNode, type) {
      return type !== "inner";
    },
    // 判断节点能否被拖拽
    allowDrag(draggingNode) {
      return !draggingNode.data.disabled;
    }
  }
};
</script>
<style lang="scss" scoped>
.tree-wrap {
  margin: 20px 0;
  .group-title {
    font-size: 12px;
    padding: 6px 12px;
    color: #999;
  }
  ::v-deep {
    .el-tree-node[draggable="false"] {
      .icon-drag-dot {
        cursor: not-allowed;
      }
    }
    .el-tree-node__content {
      width: 100%;
      @include flex-ycb;
    }
    .el-tree-node__expand-icon {
      padding: 0;
    }
    .custom-tree-node {
      flex: 1;
      user-select: none;
      @include flex-ycb;
      &.disabled {
        color: #999;
      }
      .iconfont {
        color: #ddd;
        font-size: 12px;
        padding-right: 20px;
      }
      .label {
        flex: 1;
      }
      .el-switch {
        transform: scale(0.75) !important;
      }
    }
  }
}
.actions {
  @include flex-ycb;
}
</style>
