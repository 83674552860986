import request from "@/utils/request";

// 查询平台应用列表
export function getAppList(data) {
  return request({
    url: `/api/v1/platform/manage/getAppList`,
    data
  });
}

// 查询小程序码
export function getAppQrcode(data) {
  return request({
    url: `/api/v1/platform/manage/getAppQrcode`,
    data
  });
}

// 查询平台小程序码
export function getPlatformQrCode(data) {
  return request({
    url: `/api/v1/platform/manage/getPlatformQrCode`,
    data: { ...data, showLoading: false }
  });
}

// 创建聚合平台
export function createAppInfo(data) {
  return request({
    url: `/api/v1/platform/manage/createAppInfo`,
    data
  });
}

// 编辑聚合平台
export function editAppInfo(data) {
  return request({
    url: `/api/v1/platform/manage/editAppInfo`,
    data
  });
}

// 创建认证用户
export function insertCustomerAuth(data) {
  return request({
    url: `/api/v1/platform/web/customer/auth/insertCustomerAuth`,
    data
  });
}

// 查询公众号二维码
export function getGzhQrcode(platformId) {
  return request({
    url: `/api/v1/platform/manage/${platformId}/gzh/qrcode`
  });
}

// 查询平台详情数据
export function getPlatformDetail(data) {
  return request({
    url: `/api/v1/platform/manage/getAppDetail?id=${data.platformId}`,
    method: "GET"
  });
}
