<template>
  <el-container class="message-router-view">
    <div class="header">
      <f-platform-box></f-platform-box>
      <f-logout></f-logout>
    </div>
    <el-main class="main">
      <router-view />
    </el-main>
  </el-container>
</template>

<script>
import FPlatformBox from "@/components/platform-box";
import FLogout from "@/components/logout";
export default {
  components: {
    FPlatformBox,
    FLogout
  }
};
</script>

<style lang="scss" scoped>
.message-router-view {
  height: 100vh;
  min-width: 1080px;
  overflow-x: auto;
  flex-direction: column;
  position: relative;
  .header {
    padding-right: 10px;
    height: 60px;
    background: #fff;
    @include flex-ycb;
  }
  .main {
    flex: 1;
    background: #f8f8f8;
    overflow-y: auto;
  }
}
</style>
