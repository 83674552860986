var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show,
      expression: "show"
    }],
    staticClass: "f-empty"
  }, [_vm.type === 'order' ? _c('img', {
    attrs: {
      "src": require("../../assets/empty.png")
    }
  }) : _c('img', {
    attrs: {
      "src": require("../../assets/empty2.png")
    }
  }), _c('p', {
    staticClass: "description"
  }, [_vm._v(_vm._s(_vm.description))])]);

}
var staticRenderFns = []

export { render, staticRenderFns }