<template>
  <div class="platform-box">
    <h3>选择你要管理的运营平台</h3>
    <div class="list">
      <div
        class="item"
        v-for="item of list.slice(0, 5)"
        :key="item.id"
        @click="handleClick(item)"
      >
        <el-image class="logo" :src="item.logo" fit="cover">
          <i slot="error" class="el-icon-picture-outline"></i>
        </el-image>
        <div class="txt">
          <p>{{ item.applicationName }}·{{ item.name }}</p>
          <span>{{ typeLabelMap[item.type] }}</span>
        </div>
        <i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div class="more">
      <el-link :underline="false" v-if="list.length > 5" @click="handleClick()"
        >更多平台</el-link
      >
    </div>
  </div>
</template>

<script>
import { encrypt } from "@/utils/crypto";
export default {
  data() {
    return {
      typeLabelMap: {
        1: "购房平台",
        2: "分销平台"
      }
    };
  },
  computed: {
    list() {
      return this.$userInfo.managerSite;
    }
  },
  methods: {
    handleClick(item) {
      if (item) {
        const { applicationId, type } = item;
        const url = `${location.origin}/#${
          type == 1 ? `/platform/estate/correl` : `/distribution/home`
        }?platformId=${encrypt(applicationId)}`;
        this.$emit("close");
        window.open(url);
      } else {
        window.open(`${location.origin}/#/workbench/platform`);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.platform-box {
  h3 {
    font-size: 18px;
    color: #333;
    margin: 20px 0;
  }
  .list {
    .item {
      padding: 10px;
      border-radius: 5px;
      background: #f5f5f5;
      @include flex-yc;
      &:hover {
        cursor: pointer;
        background: #eee;
      }
      & + .item {
        margin-top: 10px;
      }
      .logo {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 5px;
        font-size: 24px;
        color: #aaa;
        background: #eee;
        @include flex-xyc;
      }
      .txt {
        padding-left: 10px;
        flex: 1;
      }
      p {
        padding-bottom: 5px;
      }
      span {
        font-size: 12px;
        color: #999;
      }
    }
  }
  .more {
    margin-top: 30px;
    padding-bottom: 20px;
    text-align: center;
  }
}
</style>
