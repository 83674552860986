var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "head"
  }, [_c('h2', [_vm._v("活动参与列表")]), _c('div', {
    staticClass: "filter"
  }, [_c('el-select', {
    attrs: {
      "size": "small"
    },
    on: {
      "change": _vm.search
    },
    model: {
      value: _vm.queryData.commentType,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "commentType", $$v);
      },
      expression: "queryData.commentType"
    }
  }, _vm._l(_vm.activityList, function (item) {
    return _c('el-option', {
      key: item.type,
      attrs: {
        "value": item.type,
        "label": item.name
      }
    });
  }), 1), _c('el-radio-group', {
    attrs: {
      "size": "small"
    },
    on: {
      "change": function change($event) {
        return _vm.search('accepted');
      }
    },
    model: {
      value: _vm.queryData.accepted,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "accepted", $$v);
      },
      expression: "queryData.accepted"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": ""
    }
  }, [_vm._v("全部")]), _c('el-radio-button', {
    attrs: {
      "label": "1"
    }
  }, [_vm._v("已中奖")])], 1), _c('el-date-picker', {
    staticClass: "w250",
    attrs: {
      "size": "small",
      "type": "daterange",
      "value-format": "yyyy-MM-dd",
      "range-separator": "至",
      "start-placeholder": "开始日期",
      "end-placeholder": "结束日期"
    },
    on: {
      "change": function change($event) {
        return _vm.search('date');
      }
    },
    model: {
      value: _vm.queryData.inDate,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "inDate", $$v);
      },
      expression: "queryData.inDate"
    }
  }), _c('el-input', {
    staticClass: "w320",
    attrs: {
      "placeholder": "内容关键字",
      "size": "small"
    },
    model: {
      value: _vm.queryData.keyword,
      callback: function callback($$v) {
        _vm.$set(_vm.queryData, "keyword", $$v);
      },
      expression: "queryData.keyword"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append",
      "icon": "el-icon-search"
    },
    on: {
      "click": _vm.search
    },
    slot: "append"
  })], 1)], 1)]), _c('div', {
    staticClass: "body"
  }, [_c('el-table', {
    attrs: {
      "border": "",
      "data": _vm.list
    }
  }, [_c('el-table-column', {
    attrs: {
      "label": "用户信息",
      "width": "200"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var row = _ref.row;
        return [_c('div', {
          staticClass: "user"
        }, [_c('el-image', {
          staticClass: "avatar",
          attrs: {
            "src": row.customerAvatarPath
          }
        }), _c('div', {
          staticClass: "txt"
        }, [_c('p', [_vm._v(_vm._s(row.customerName))]), _c('span', [_vm._v(_vm._s(row.distributionMechanismName))])])], 1)];
      }
    }])
  }), _vm.queryData.commentType == 24 ? _c('el-table-column', {
    attrs: {
      "label": "是否选中",
      "width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var row = _ref2.row;
        return [row.replyType == 10 ? _c('div', [_c('span', {
          staticClass: "green"
        }, [_vm._v("是")]), _c('p', [_vm._v("选中手机：" + _vm._s(row.distributionUserPhone || "-"))])]) : _c('p', [_vm._v("否")])];
      }
    }], null, false, 3954323557)
  }) : _vm._e(), _c('el-table-column', {
    attrs: {
      "label": "是否中奖",
      "width": "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var row = _ref3.row;
        return [row.accepted ? _c('div', [_c('span', {
          staticClass: "green"
        }, [_vm._v("是")]), _c('p', [_vm._v("中奖编号：" + _vm._s(row.acceptNo || "-"))]), _c('p', [_vm._v("中奖手机：" + _vm._s(row.distributionUserPhone || "-"))])]) : _c('p', [_vm._v("否")])];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "评论内容"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var row = _ref4.row;
        return [_c('div', {
          staticClass: "comment-content",
          domProps: {
            "innerHTML": _vm._s(row.content.replace(/\n/g, '<br>'))
          }
        }), row.attachs && row.attachs.length ? _c('div', {
          staticClass: "images"
        }, _vm._l(row.attachs, function (item) {
          return _c('el-image', {
            key: item.fileUrl,
            attrs: {
              "fit": "cover",
              "src": item.fileUrl,
              "preview-src-list": row.attachs.map(function (item) {
                return item.fileUrl;
              })
            }
          });
        }), 1) : _vm._e()];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "评论时间",
      "width": "160"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var row = _ref5.row;
        return [_vm._v(" " + _vm._s(_vm.$time(row.inDate).format("yyyy-MM-DD HH:mm:ss")) + " ")];
      }
    }])
  }), _c('el-table-column', {
    attrs: {
      "label": "是否重复",
      "width": "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var row = _ref6.row;
        return [_c('p', [row.repeat ? _c('span', {
          staticClass: "red"
        }, [_vm._v("是")]) : _c('span', [_vm._v("否")])])];
      }
    }])
  }), _vm.queryData.commentType != 23 ? _c('el-table-column', {
    attrs: {
      "label": "操作",
      "width": "90"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var row = _ref7.row;
        return [_vm.queryData.commentType == 24 ? _c('el-link', {
          attrs: {
            "type": row.replyType == 10 ? 'danger' : 'primary'
          },
          on: {
            "click": function click($event) {
              return _vm.changeAccept(row);
            }
          }
        }, [_vm._v(_vm._s(row.replyType == 10 ? "取消选中" : "设为选中"))]) : _vm._e(), _vm.queryData.commentType != 24 || _vm.queryData.commentType == 24 && row.replyType == 10 ? [!row.accepted ? _c('el-link', {
          attrs: {
            "type": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.changeAccept(row);
            }
          }
        }, [_vm._v("设为中奖")]) : _c('el-link', {
          attrs: {
            "type": "danger"
          },
          on: {
            "click": function click($event) {
              return _vm.changeAccept(row);
            }
          }
        }, [_vm._v("取消中奖")])] : _vm._e()];
      }
    }], null, false, 2341829304)
  }) : _vm._e()], 1)], 1), _c('f-pagination', {
    attrs: {
      "fixed": "",
      "page-num": _vm.queryData.pageNum,
      "page-size": _vm.queryData.pageSize,
      "total": _vm.total
    },
    on: {
      "update:pageNum": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:page-num": function updatePageNum($event) {
        return _vm.$set(_vm.queryData, "pageNum", $event);
      },
      "update:pageSize": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "update:page-size": function updatePageSize($event) {
        return _vm.$set(_vm.queryData, "pageSize", $event);
      },
      "change": _vm.getTempActivityCommentList
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }