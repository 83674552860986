<template>
  <el-dropdown>
    <div class="account-bar">
      <img :src="userInfo.avatarPath || $defaultAvatarUrl" class="avatar" />
      <p class="name">
        {{
          userInfo.nickName ||
            userInfo.realName ||
            userInfo.userName ||
            "暂无信息"
        }}
      </p>
      <i class="el-icon-caret-bottom"></i>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    }
  },
  methods: {
    //退出登录
    logout() {
      // 如果从P+跳入
      if (sessionStorage.getItem("fromPCN")) {
        // 正式
        if (location.hostname == "vip.fangyibao.cn") {
          location.href = `https://pcn.fangmao.com/#/login`;
          // 测试或本地
        } else {
          location.href = `http://${location.hostname}:${
            location.hostname.includes("101.37") ? "30013" : "8100"
          }/#/login`;
        }
      } else {
        this.$router.push("/login");
      }
      sessionStorage.clear();
    }
  }
};
</script>

<style lang="scss" scoped>
.account-bar {
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.75;
  }
  .name {
    padding: 0 8px;
  }
}
</style>
