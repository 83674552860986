var render = function render(){
  var _vm$$userInfo$manager;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "aside-wrap"
  }, [_c('div', {
    staticClass: "collapse-btn",
    attrs: {
      "title": _vm.isCollapse ? '展开菜单' : '收起菜单'
    },
    on: {
      "click": function click($event) {
        _vm.isCollapse = !_vm.isCollapse;
      }
    }
  }, [_vm.isCollapse ? _c('i', {
    staticClass: "el-icon-s-unfold"
  }) : _c('i', {
    staticClass: "el-icon-s-fold"
  })]), _c('el-aside', {
    staticClass: "navMenu",
    class: {
      collapse: _vm.isCollapse
    },
    attrs: {
      "id": "f-aside"
    }
  }, [_vm.systemType === 1 ? _c('div', {
    staticClass: "platform-info"
  }, [_c('img', {
    staticClass: "default-logo",
    attrs: {
      "src": require("../../assets/logo.png")
    }
  })]) : _vm._e(), _vm.systemType === 2 ? _c('div', {
    staticClass: "platform-info"
  }, [_c('el-image', {
    staticClass: "logo",
    attrs: {
      "fit": "cover",
      "src": _vm.$estateInfo.estateLogo
    }
  }, [_c('i', {
    staticClass: "el-icon-picture",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  })]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$estateInfo.estateName) + " ")])], 1) : _vm._e(), _vm.systemType === 3 || _vm.systemType === 4 ? _c('div', {
    staticClass: "platform-info"
  }, [_c('el-image', {
    staticClass: "logo",
    attrs: {
      "fit": "cover",
      "src": _vm.$platformInfo.logo
    }
  }, [_c('i', {
    staticClass: "el-icon-picture",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  })]), _vm.$platformInfo.name ? _c('div', {
    staticClass: "text"
  }, [_c('p', [_vm._v(_vm._s(_vm.$platformInfo.name) + "-" + _vm._s(_vm.$platformInfo.applicationName))]), _vm.systemType == 4 ? _c('p', {
    staticClass: "desc"
  }, [_vm._v("分销平台")]) : _vm._e()]) : _vm._e(), ((_vm$$userInfo$manager = _vm.$userInfo.managerSite) === null || _vm$$userInfo$manager === void 0 ? void 0 : _vm$$userInfo$manager.length) > 1 ? _c('el-popover', {
    attrs: {
      "placement": "bottom",
      "width": "300",
      "trigger": "click",
      "popper-class": "switch-platform-popover"
    },
    model: {
      value: _vm.platformPopoverVisible,
      callback: function callback($$v) {
        _vm.platformPopoverVisible = $$v;
      },
      expression: "platformPopoverVisible"
    }
  }, [_c('switch-platform', {
    on: {
      "close": function close($event) {
        _vm.platformPopoverVisible = false;
      }
    }
  }), _c('div', {
    staticClass: "switch-icon",
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_c('i', {
    staticClass: "el-icon-sort"
  })])], 1) : _vm._e()], 1) : _vm._e(), _c('el-menu', {
    ref: "menu",
    attrs: {
      "background-color": _vm.$styles.deepBlue,
      "text-color": _vm.$styles.white,
      "active-text-color": _vm.$styles.white,
      "default-active": _vm.defaultActive,
      "default-openeds": _vm.defaultOpeneds,
      "router": "",
      "unique-opened": ""
    }
  }, [_vm._l(_vm.routerMenu, function (item) {
    return [item.children && item.children.length ? _c('el-submenu', {
      key: item.path,
      attrs: {
        "index": item.path
      }
    }, [_c('template', {
      slot: "title"
    }, [_c('i', {
      class: "el-icon-".concat(item.icon)
    }), _vm._v(_vm._s(item.name) + " ")]), _c('el-menu-item-group', _vm._l(item.children, function (item2, index2) {
      return _c('el-menu-item', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: _vm.showMenuItem(item2.path),
          expression: "showMenuItem(item2.path)"
        }],
        key: index2,
        attrs: {
          "index": item2.path
        }
      }, [_vm._v(_vm._s(item2.name) + " ")]);
    }), 1)], 2) : [_c('el-menu-item', {
      key: item.path,
      attrs: {
        "index": item.path,
        "route": item.path
      }
    }, [_c('i', {
      class: "el-icon-".concat(item.icon)
    }), _c('span', {
      attrs: {
        "slot": "title"
      },
      slot: "title"
    }, [_vm._v(_vm._s(item.name))])])]];
  })], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }