import qs from "querystring";
import store from "@/store";
import { getEstateDetail } from "@/api/estate";
import { getPlatformDetail } from "@/api/platform";
import { decrypt } from "@/utils/crypto";

//获取当前楼盘数据
async function getEstateInfo(query) {
  const estateInfo = store.state.estateInfo;
  query = query || location.href.split("?")[1];
  let estateId = null;
  if (query) {
    estateId = qs.parse(query).estateId;
  } else if (estateInfo) {
    estateId = estateInfo.estateId;
  }
  if (!estateId) return;
  // 解密
  estateId = decrypt(estateId);
  store.commit("SET_ESTATE_INFO", { ...(estateInfo || {}), estateId });
  const res = await getEstateDetail({ estateId }, false);
  if (res) {
    const { baseInfo, planningInfo, supportInfo, estateId } = res;
    store.commit("SET_ESTATE_INFO", {
      ...baseInfo,
      ...planningInfo,
      ...supportInfo,
      estateId
    });
  }
}

//获取当前楼盘数据
async function getPlatformInfo(query) {
  const platformInfo = store.state.platformInfo;
  query = query || location.href.split("?")[1];
  let platformId = null;
  if (query) {
    platformId = qs.parse(query).platformId;
  } else if (platformInfo) {
    platformId = platformInfo.platformId;
  }

  if (!platformId) return;
  // 解密
  platformId = decrypt(platformId);
  store.commit("SET_PLATFORM_INFO", { ...(platformInfo || {}), platformId });
  const res = await getPlatformDetail({ platformId }, false);
  if (res) {
    store.commit("SET_PLATFORM_INFO", { ...res, platformId });
  }
}

getEstateInfo();
getPlatformInfo();

export { getEstateInfo, getPlatformInfo };
