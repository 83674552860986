<template>
  <div class="f-empty" v-show="show">
    <img src="../../assets/empty.png" v-if="type === 'order'" />
    <img src="../../assets/empty2.png" v-else />
    <p class="description">{{ description }}</p>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "order" // order box
    },
    description: {
      type: String,
      default: "~暂无数据~"
    }
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 500);
  }
};
</script>

<style scoped lang="scss">
.f-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
  padding: 50px 0;
  img {
    fill: #dcdde0;
    width: 200px;
    vertical-align: top;
  }
  .description {
    color: #aaa;
  }
}
</style>
