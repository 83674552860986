import router from "@/router";
import qs from "qs";
import { encrypt } from "./crypto";
import { getRawType } from "./index";

/**
 * @路由拦截器
 * @对路由跳转参数进行加密
 * */
const routeInterceptor = () => {
  const methodsToPatch = ["push", "replace"];
  // 对参数对象每一项加密
  const encryptObjectValue = params => {
    if (params && Object.keys(params)) {
      for (const key in params) {
        // 除路由约定缓存的字段 全部加密
        if (key !== "cached") {
          params[key] = encrypt(params[key]);
        }
      }
    }
    return params;
  };

  /**
   * @params参数加密
   * @这样跳转是没问题的_但是其他地方匹配过path的地方可能出错_后期看是否需要优化实现
   * */
  // const encryptPathParams = path => {
  //   // 目前我们的params参数均为path最后的数字 所以可直接这样替换加密
  //   const re = /\/(\d+)($|\?)/;
  //   return path.replace(re, ($1, $2) => `/${encrypt($2)}`);
  // };

  methodsToPatch.forEach(method => {
    // 储存原始router方法
    const original = router[method];
    router[method] = function(options) {
      // 如果传入的参数是 string ，如：router.push(`work/business/commission?cached=12454132486132`)
      if (typeof options === "string") {
        let [path, query] = options.split("?");
        // 替换加密 path中携带的params参数
        // path = encryptPathParams(path);
        // 如果有query参数
        if (query) {
          // querystring转换为 object
          const params = qs.parse(query);
          // 对每一项进行进行加密
          for (const key in params) {
            // 除路由约定缓存的字段 全部加密
            if (key !== "cached") {
              params[key] = encrypt(params[key]);
            }
          }
          // 再将对象转换为querystring 和 path拼接起来
          options = `${path}?${qs.stringify(params)}`;
        }
        // 如果传入的是对象
      } else if (getRawType(options) === "object") {
        // query params 参数加密
        options.query = encryptObjectValue(options.query);
        // options.params = encryptObjectValue(options.params);
      }
      original.call(this, options);
    };
  });
};

export { routeInterceptor };
