import Vue from "vue";
import VueRouter from "vue-router";
import qs from "querystring";
import Home from "@/views/home.vue"; //首页
import MessageView from "@/views/distribution/appManage/message/routerView";
import TempActivity from "@/views/temp/activityManage";
import store from "@/store";
import { flatJsonTree, showError } from "@/utils";
import { decrypt } from "@/utils/crypto";

// 是否在微应用当中
const isMicroAppEnvironment = window.__MICRO_APP_ENVIRONMENT__;

const Admin = () => import("@/views/distribution/userManage/admin/index");

// 路由跳转解密query参数
const decryptQuery = ({ query }) => {
  for (const key in query) {
    if (key !== "cached") {
      query[key] = decrypt(query[key]);
    }
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/login")
  },
  {
    path: "/404",
    component: () => import("@/views/404")
  },
  {
    path: "*",
    redirect: "/404",
    component: () => import("@/views/404")
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "/login",
    children: [
      {
        path: "/workbench/estate",
        component: () => import("@/views/estate/index")
      }, //楼盘项目

      {
        path: "/workbench/platform",
        component: () => import("@/views/platform/index/index")
      },
      {
        path: "/setting/info",
        component: () => import("@/views/setting/info")
      },
      {
        path: "/setting/admin",
        component: Admin
      }
    ]
  },
  {
    path: "/platform",
    name: "聚合平台",
    component: Home,
    children: [
      {
        path: "/platform/estate/correl",
        component: () => import("@/views/platform/estate/correl")
      }, //关联楼盘
      {
        path: "/platform/estate/recommend",
        component: () => import("@/views/platform/estate/recommend")
      }, //推荐楼盘
      {
        path: "/platform/visitor/outline",
        component: () => import("@/views/platform/visitor/outline")
      },
      {
        path: "/platform/visitor/auth",
        component: () => import("@/views/platform/visitor/auth")
      }, //用户认证
      {
        path: "/platform/appManage/rank",
        component: () => import("@/views/platform/appManage/rank")
      }, //榜单管理
      {
        path: "/platform/appManage/live",
        component: () => import("@/views/platform/appManage/live")
      }, //直播活动
      {
        path: "/platform/appManage/activity",
        component: () => import("@/views/platform/appManage/activity")
      }, //评论管理
      {
        path: "/platform/appManage/activity2",
        component: () => import("@/views/platform/appManage/activity2")
      }, //天府里188万活动
      {
        path: "/platform/appManage/article",
        component: () => import("@/views/platform/appManage/article")
      }, //文章管理
      {
        path: "/platform/appManage/question",
        component: () => import("@/views/platform/appManage/question")
      }, //问答管理
      {
        path: "/platform/appManage/tf",
        component: () => import("@/views/platform/appManage/tf")
      }, //挑房管理
      {
        path: "/platform/appManage/banner",
        component: () => import("@/views/platform/appManage/banner")
      }, //广告管理
      {
        path: "/platform/appManage/housingCommission",
        component: () => import("@/views/platform/appManage/housingCommission")
      }, //找房委托
      {
        path: "/platform/appManage/specialCar",
        component: () => import("@/views/platform/appManage/specialCar")
      } //看房专车
    ]
  },
  {
    path: "/distribution",
    name: "分销平台",
    component: Home,
    children: [
      // 首页
      {
        path: "/distribution/home",
        component: () => import("@/views/distribution/home")
      },
      {
        path: "/distribution/estate/distribution",
        component: () => import("@/views/distribution/estate/distribution")
      }, // 分销平台-分销楼盘
      {
        path: "/distribution/estate/recommend",
        component: () => import("@/views/platform/estate/recommend")
      }, // 分销平台-推荐楼盘
      {
        path: "/distribution/userManage/outline",
        component: () => import("@/views/platform/visitor/outline")
      }, // 分销平台-访问概要
      {
        path: "/distribution/userManage/registeredUser",
        component: () =>
          import("@/views/distribution/userManage/registeredUser/index")
      }, // 分销平台-注册用户
      {
        path: "/distribution/appManage/report",
        component: () => import("@/views/distribution/appManage/report/index")
      }, // 报备带看
      {
        path: "/distribution/userManage/auth",
        component: () => import("@/views/platform/visitor/auth")
      }, // 分销平台-用户认证
      {
        path: "/distribution/userManage/channel",
        component: () => import("@/views/distribution/userManage/channel/index")
      }, // 分销平台-渠道管理
      {
        path: "/distribution/userManage/admin",
        component: Admin
      }, // 分销平台-管理员设置
      {
        path: "/distribution/appManage/banner",
        component: () => import("@/views/platform/appManage/banner")
      }, // 分销平台-广告管理
      {
        path: "/distribution/appManage/houseSalesControl",
        component: () =>
          import("@/views/distribution/appManage/houseSalesControl/index")
      }, // 分销平台-房源销控
      {
        path: "/distribution/appManage/settlement",
        component: () =>
          import("@/views/distribution/appManage/settlement/index")
      }, // 分销平台-甲方结算
      {
        path: "/distribution/appManage/signedOrder",
        component: () =>
          import("@/views/distribution/appManage/signedOrder/index")
      }, // 认购签约
      {
        path: "/distribution/appManage/signedOrder/:id",
        component: () =>
          import("@/views/distribution/appManage/signedOrder/detail")
      }, // 认购签约订单详情,
      {
        path: "/distribution/appManage/message",
        component: () => import("@/views/distribution/appManage/message/index")
      }, // 群发消息列表
      {
        path: "/distribution/appManage/transactionManage",
        component: () =>
          import("@/views/distribution/appManage/transactionManage/index")
      } // 成交管理
    ]
  },
  {
    path: "/distribution",
    name: "群发消息",
    component: MessageView,
    children: [
      // 新增编辑图文消息
      {
        path: "/distribution/appManage/message/graphicEdit",
        component: () =>
          import("@/views/distribution/appManage/message/edit/graphic")
      },
      // 新增编辑图片消息
      {
        path: "/distribution/appManage/message/imageEdit",
        component: () =>
          import("@/views/distribution/appManage/message/edit/image")
      },
      // 新增编辑文字消息
      {
        path: "/distribution/appManage/message/textEdit",
        component: () =>
          import("@/views/distribution/appManage/message/edit/text")
      },
      // 预览图文消息
      {
        path: "/distribution/appManage/message/graphicView",
        component: () =>
          import("@/views/distribution/appManage/message/view/graphic")
      }
    ]
  },
  {
    path: "/estate",
    name: "楼盘项目",
    component: Home,
    children: [
      {
        path: "/estate/subscription/order",
        component: () => import("@/views/estate/subscription/order/index")
      }, //认购订单
      {
        path: "/estate/marketing",
        component: () => import("@/views/estate/subscription/marketing")
      }, //营销活动
      {
        path: "/estate/subscriptionFormula",
        component: () =>
          import("@/views/estate/subscription/subscriptionFormula")
      }, //认购公式
      {
        path: "/estate/appointmentRegistration",
        component: () =>
          import("@/views/estate/subscription/appointmentRegistration")
      }, //预约登记
      {
        path: "/estate/dictionary/baseInfo",
        component: () => import("@/views/estate/dictionary/baseInfo")
      }, //楼盘字典基础信息
      {
        path: "/estate/dictionary/houseType",
        component: () => import("@/views/estate/dictionary/houseType")
      }, //楼盘字典户型管理
      {
        path: "/estate/dictionary/coupon",
        component: () => import("@/views/estate/dictionary/coupon")
      }, //优惠券
      {
        path: "/estate/dictionary/comment",
        component: () => import("@/views/estate/dictionary/comment")
      }, //楼盘字典评论管理
      {
        path: "/estate/dictionary/album",
        component: () => import("@/views/estate/dictionary/album")
      }, //楼盘字典相册管理
      {
        path: "/estate/dictionary/video",
        component: () => import("@/views/estate/dictionary/video")
      }, //楼盘字典视频管理
      {
        path: "/estate/dictionary/trends",
        component: () => import("@/views/estate/dictionary/trends")
      }, //楼盘字典动态管理
      {
        path: "/estate/distribution/channel",
        component: () => import("@/views/estate/distribution/channel")
      },
      {
        path: "/estate/dictionary/article",
        component: () => import("@/views/platform/appManage/article")
      }, //文章管理
      {
        path: "/estate/distribution/rule",
        component: () => import("@/views/estate/distribution/rule")
      },
      {
        path: "/estate/distribution/project",
        component: () => import("@/views/estate/distribution/project")
      },
      {
        path: "/estate/distribution/reward",
        component: () => import("@/views/estate/distribution/reward")
      },
      {
        path: "/estate/distribution/programme",
        component: () => import("@/views/estate/distribution/programme")
      },
      {
        path: "/estate/distribution/report",
        component: () =>
          import("@/views/distribution/appManage/estateReport/index")
      } // 报备带看
    ]
  },
  {
    path: "/opening",
    name: "开盘管理",
    component: () => import("@/views/opening/home"),
    redirect: "/opening/index",
    children: [
      {
        path: "/opening/index",
        component: () => import("@/views/opening/activity/index")
      },
      {
        path: "/opening/house",
        component: () => import("@/views/opening/house/index")
      },
      {
        path: "/opening/car",
        component: () => import("@/views/opening/car/index")
      },
      {
        path: "/opening/generalLayout",
        component: () => import("@/views/opening/generalLayout/index")
      },
      {
        path: "/opening/marketing",
        component: () => import("@/views/opening/marketing")
      }, //营销活动
      {
        path: "/opening/subscriptionFormula",
        component: () =>
          import("@/views/estate/subscription/subscriptionFormula")
      } //认购公式
    ]
  },
  // 临时活动
  {
    path: "/temp/activity/yuanqili",
    name: "元气里活动",
    component: TempActivity
  },
  {
    path: "/temp/activity/tianfuyinghui",
    name: "天府迎辉",
    component: TempActivity
  },
  {
    path: "/temp/activity/ersanli",
    name: "大源贰叁里",
    component: TempActivity
  }
];

const router = new VueRouter({
  routes
});

function hasPermissionMenus(toPath) {
  /**
   * 判断用户是否拥有即将访问的菜单的权限（没权限也可能没该页面）
   * @param {String} toPath 即将访问的页面路径
   **/

  const estateRe = /(^\/estate\/)/; //楼盘基础信息管理
  const platformRe = /(^\/platform\/)/; //聚合平台
  const whiteRe = /((^\/opening)|(^\/temp\/))/; //白名单路由
  const distributionRe = /(^\/distribution)/; //分销平台
  const baseMenus = flatJsonTree(store.state.menuInfo.baseMenus || []);
  const menus = [...baseMenus, { menuPath: "/login" }, { menuPath: "/404" }]; //手动加入 login等白名单页面
  //访问的白名单页面
  if (whiteRe.test(toPath)) {
    return true;
  }

  //是否拥有楼盘基础信息管理的权限
  if (
    estateRe.test(toPath) &&
    !menus.some(item => item.menuPath == "/workbench/estate")
  ) {
    return false;
  }

  //是否拥有聚合平台的权限
  if (
    platformRe.test(toPath) &&
    !menus.some(item => item.menuPath == "/workbench/platform")
  ) {
    return false;
  }

  //是否拥有分销平台的权限
  if (
    distributionRe.test(toPath) &&
    !menus.some(item => item.menuPath == "/workbench/platform")
  ) {
    return false;
  }

  //是否拥有即将访问的菜单权限
  if (
    !estateRe.test(toPath) &&
    !platformRe.test(toPath) &&
    !distributionRe.test(toPath) &&
    !menus.some(item => item.menuPath == toPath)
  ) {
    return false;
  }

  return true;
}

router.beforeEach((to, from, next) => {
  try {
    // 解密路由参数
    decryptQuery(to, false);
  } catch (err) {
    showError("url参数错误");
  }
  // //用于判断是楼盘管理或者还是聚合平台相关路径
  const pathRe = /(^\/estate\/)|(^\/platform\/)|(^\/distribution\/)|(^\/opening\/)/;
  // 当前页面路径
  const currentPath = location.href.split("/#")[1];
  if (!hasPermissionMenus(to.path)) {
    next("/404");
    return;
  }
  // 当前路径根目录名字
  let currentRootPathName = "";
  if (currentPath) {
    currentRootPathName = currentPath.split("/")[1];
  }
  // 跳转路径根目录名字
  const toRootPathName = to.path.split("/")[1];
  // 如果跳转的路径根目录名字跟当前根目录名字不一致 视为跳入新业务应用 打开新窗口
  // 如：/distribution/estate/distribution 跳转到 /estate/dictionary/baseInfo distribution 和 estate不属于同一个根目录则打开新窗口
  if (
    currentRootPathName !== toRootPathName &&
    pathRe.test(to.path) &&
    from.path !== "/login" &&
    !isMicroAppEnvironment
  ) {
    window.open(
      `${location.protocol}//${location.host}/#${to.path}?${qs.stringify(
        to.query
      )}`
    );
  } else {
    next();
  }
});

export default router;
