var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "f-pagination-wrapper",
    class: {
      fixed: _vm.fixed,
      shadow: _vm.shadow
    }
  }, [_c('div', {
    staticClass: "f-pagination"
  }, [_c('el-pagination', _vm._g(_vm._b({
    ref: "pagination",
    attrs: {
      "prev-text": "上一页",
      "next-text": "下一页",
      "layout": "total, sizes, prev, pager, next, jumper",
      "total": _vm.total,
      "current-page": _vm.pageNum_,
      "page-size": _vm.pageSize,
      "page-sizes": _vm.pageSizes
    },
    on: {
      "update:currentPage": function updateCurrentPage($event) {
        _vm.pageNum_ = $event;
      },
      "update:current-page": function updateCurrentPage($event) {
        _vm.pageNum_ = $event;
      },
      "size-change": function sizeChange($event) {
        return _vm.handleChange($event, 'pageSize');
      },
      "current-change": function currentChange($event) {
        return _vm.handleChange($event, 'pageNum');
      }
    }
  }, 'el-pagination', _vm.$attrs, false), _vm.$listeners))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }