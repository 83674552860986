var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-container', {
    staticClass: "home"
  }, [!_vm.isMicroAppEnvironment ? _c('navMenu') : _vm._e(), _c('el-container', {
    staticClass: "container"
  }, [!_vm.isMicroAppEnvironment ? _c('homeHeader') : _vm._e(), _c('el-main', {
    staticClass: "main",
    style: _vm.mainStyle
  }, [_c('router-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }