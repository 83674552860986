//https://blog.csdn.net/caiminiu/article/details/87071668
/**
 * 数字精确计算
 * **/

export const accAdd = (data1, data2) => {
  //数字相加
  var r1, r2, m, c;
  try {
    r1 = data1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = data2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      data1 = Number(data1.toString().replace(".", ""));
      data2 = Number(data2.toString().replace(".", "")) * cm;
    } else {
      data1 = Number(data1.toString().replace(".", "")) * cm;
      data2 = Number(data2.toString().replace(".", ""));
    }
  } else {
    data1 = Number(data1.toString().replace(".", ""));
    data2 = Number(data2.toString().replace(".", ""));
  }
  return (data1 + data2) / m;
};

export const numSub = (data1, data2) => {
  //相减
  var num, num1, num2;
  var precision; // 精度
  try {
    num1 = data1.toString().split(".")[1].length;
  } catch (e) {
    num1 = 0;
  }
  try {
    num2 = data2.toString().split(".")[1].length;
  } catch (e) {
    num2 = 0;
  }
  num = Math.pow(10, Math.max(num1, num2));
  precision = num1 >= num2 ? num1 : num2;
  return Number(
    (Math.round(data1 * num - data2 * num) / num).toFixed(precision)
  );
};

export const accMulti = (data1, data2) => {
  //相乘
  var baseData = 0;
  if (!data1 || !data2) return 0;
  try {
    baseData += data1.toString().split(".")[1].length;
  } catch (err) {
    // console.log(err);
  }
  try {
    baseData += data2.toString().split(".")[1].length;
  } catch (err) {
    // console.log(err);
  }
  return (
    (Number(data1.toString().replace(".", "")) *
      Number(data2.toString().replace(".", ""))) /
    Math.pow(10, baseData)
  );
};

export const division = (arg1, arg2) => {
  //相除
  var t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = new String(arg1).split(".")[1].length;
  } catch (err) {
    // console.log(err);
  }
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (err) {
    // console.log(err);
  }
  r1 = Number(arg1.toString().replace(".", ""));
  r2 = Number(arg2.toString().replace(".", ""));
  //放大倍数后两个数相除 后，乘以两个小数位数长度相减后的10的次幂
  var newData = accMulti(r1 / r2, Math.pow(10, t2 - t1));
  //保留2个小数位数：return newData.toFixed(2)
  return newData;
};

export const keepDecimal = (num, count = 2) => {
  // 保留小数点后多少位（不四舍五入）
  const num1 = accMulti(num, Math.pow(10, count));
  return Math.floor(num1) / Math.pow(10, count);
};
