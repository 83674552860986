var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-table', _vm._g(_vm._b({
    ref: "table"
  }, 'el-table', _vm.$attrs, false), _vm.$listeners), [_vm._l(_vm.columns, function (item, index) {
    return [item.type === 'selection' ? _c('el-table-column', _vm._b({
      key: index
    }, 'el-table-column', item, false)) : _c('el-table-column', _vm._b({
      key: index,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(scope) {
          return [item.render ? _c('c-render', {
            attrs: {
              "scope": scope,
              "render": item.render,
              "tag": item.renderTag
            }
          }) : item.isComma ? _c('span', {
            class: item.class,
            style: item.style
          }, [scope.row[item.prop] || scope.row[item.prop] == 0 ? _c('span', [_vm._v(_vm._s(item.isPrice ? "¥" : "") + _vm._s(_vm._f("toThousands")(scope.row[item.prop])))]) : _c('span', [_vm._v(_vm._s(item.placeholder || "-"))])]) : item.isDate && scope.row[item.prop] ? _c('span', [_vm._v(" " + _vm._s(_vm.$format.date(scope.row[item.prop], item.dateType)) + " ")]) : _c('span', {
            class: item.class,
            style: item.style
          }, [_vm._v(_vm._s(scope.row[item.prop] || item.placeholder || "") + " ")])];
        }
      }], null, true)
    }, 'el-table-column', item, false))];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }