import request from "@/utils/request";

// 查询活动主题列表
export const getActivityList = data =>
  request({
    url: `/api/v1/platform/web/activity/getActivityList`,
    data
  });

// 查询活动评论列表
export const getWebCommentPage = data =>
  request({
    url: `/api/v1/platform/web/activity/getWebCommentPage`,
    data
  });

// 查询活动奖品列表
export const getActivityPrizeList = data =>
  request({
    url: `/api/v1/platform/web/activity/getActivityPrizeList`,
    data
  });

// 删除评论
export const deleteComment = data =>
  request({
    url: `/api/v1/platform/web/activity/deleteComment`,
    data
  });

// 评论中奖
export const commentGetPrize = data =>
  request({
    url: `/api/v1/platform/web/activity/commentGetPrize`,
    data
  });

// 新增奖品
export const addPrize = data =>
  request({
    url: `/api/v1/platform/web/activity/addPrize`,
    data
  });

// 删除奖品
export const deletePrize = data =>
  request({
    url: `/api/v1/platform/web/activity/deletePrize`,
    data
  });

// 编辑奖品
export const editPrize = data =>
  request({
    url: `/api/v1/platform/web/activity/editPrize`,
    data
  });

// 给评论者推送消息
export const pushActivityPrizeMessage = data =>
  request({
    url: `/api/v1/platform/web/activity/pushActivityPrizeMessage`,
    data
  });

// 取消中奖
export const commentCancelPrize = data =>
  request({
    url: `/api/v1/platform/web/activity/commentCancelPrize`,
    data
  });

/**********************        天府里188万活动           ***********************/

//查询活动参与人列表
export function getJoinUserList(data) {
  return request({
    url: `/api/v1/platform/helpActivity/getJoinUserList`,
    data
  });
}

//查询助力日期
export function getActivityDateStr(data) {
  return request({
    url: `/api/v1/platform/helpActivity/getActivityDateStr`,
    data
  });
}

//导出中奖名单
export function exportWinningList(data) {
  return request({
    url: `/api/v1/platform/helpActivity/exportWinningList`,
    data
  });
}

//设置中奖
export function editWinning(data) {
  return request({
    url: `/api/v1/platform/helpActivity/editWinning`,
    data
  });
}

//查询中奖导出历史记录
export function getWinningFileDateStr(data) {
  return request({
    url: `/api/v1/platform/helpActivity/getWinningFileDateStr`,
    data
  });
}

//获取临时活动评论列表
export function getTempActivityCommentList(data) {
  return request({
    url: `/api/v1/estate/comment/manage/activity`,
    data,
    method: "GET"
  });
}

//临时活动 设为中奖
export function acceptComment(id) {
  return request({
    url: `/api/v1/estate/comment/manage/accept/${id}`
  });
}

//临时活动 取消中奖
export function cancelAcceptedComment(id) {
  return request({
    url: `/api/v1/estate/comment/manage/accept/${id}/cancel`
  });
}

//临时活动 活动类型列表
export function getActivityTypeList() {
  return request({
    url: `/api/v1/platform/web/activity/list`,
    headers: { platformId: 34 }
  });
}

//征图活动 选择结果
export function acceptVote(params) {
  return request({
    url: `/api/v1/estate/comment/manage/vote/${params.id}/select`,
    params
  });
}
