import request from "@/utils/request";

// 查询企业信息
export function getEnterpriseInfoVo(data) {
  return request({
    url: `/api/v1/fyb/web/enterprise/manage/getEnterpriseInfoVo`,
    method: "post",
    data
  });
}

// 编辑企业信息
export function editEnterpriseInfo(data) {
  return request({
    url: `/api/v1/fyb/web/enterprise/manage/editEnterpriseInfo`,
    method: "post",
    data
  });
}

// 创建企业
export function createEnterpriseInfo(data) {
  return request({
    url: `/api/v1/fyb/web/enterprise/manage/createEnterpriseInfo`,
    method: "post",
    data
  });
}

// 查询企业管理员列表
export function getEnterpriseUserList(data) {
  return request({
    url: `/api/v1/fyb/web/user/manage/getEnterpriseUserList`,
    method: "post",
    data
  });
}

// 根据名字、电话查询用户列表
export function getUserListByKeyword(data) {
  return request({
    url: `/api/v1/fyb/web/user/manage/getUserListByKeyword`,
    method: "post",
    data
  });
}

// 查询菜单树
export function getMenuTree(data) {
  return request({
    url: `/api/v1/fyb/web/user/manage/getMenuTree`,
    method: "post",
    data
  });
}

// 检验手机号是否已经注册
export function checkCellphone(data, showErrorMessage) {
  return request({
    url: `/api/v1/fyb/web/user/manage/checkCellphone`,
    method: "post",
    data,
    showErrorMessage
  });
}

// 创建企业管理员
export function createEnterpriseUser(data) {
  return request({
    url: `/api/v1/fyb/web/user/manage/createEnterpriseUser`,
    method: "post",
    data
  });
}

// 编辑企业管理员
export function editEnterpriseUser(data) {
  return request({
    url: `/api/v1/fyb/web/user/manage/editEnterpriseUser`,
    method: "post",
    data
  });
}

// 删除企业管理员
export function deleteEnterpriseUser(data) {
  return request({
    url: `/api/v1/fyb/web/user/manage/deleteEnterpriseUser`,
    method: "post",
    data
  });
}

// 根据名字、电话查询平台小程序用户列表
export function getCustomerListByKeyword(data) {
  return request({
    url: `/api/v1/platform/web/customer/auth/getCustomerListByKeyword`,
    method: "post",
    data
  });
}

// 查询菜单树，获取平台菜单
export function getMenu(headers) {
  return request({
    url: `/api/v1/platform/manage/menu`,
    method: "post",
    headers
  });
}
