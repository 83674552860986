import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const getS = (name, isObjectRes = true) => {
  const res = sessionStorage.getItem(name);
  if (isObjectRes) {
    if (res) {
      return JSON.parse(res);
    } else {
      return {};
    }
  }
  return res;
};
const setS = (name, val) => sessionStorage.setItem(name, val);

export default new Vuex.Store({
  state: {
    userInfo: getS("fyb-user-info"),
    estateInfo: getS("fyb-current-estate-info"),
    platformInfo: getS("fyb-current-platform-info"),
    menuInfo: getS("fyb-permission-menus"),
    openingCarQuery: {
      areaId: "",
      parkingPlaceNo: "", // 车位号
      salesStatusList: [], //销售状态
      parkingPlaceTypeList: [], //车位类型
      openQuotationIdList: [] //开盘活动ID
    },
    carAreas: [],
    refreshFlag: false,
    carActiveId: ""
  },
  getters: {
    estateInfo(state) {
      //获取当前楼盘项目信息
      if (state.estateInfo) {
        return getS(`fyb-current-estate-info"${state.estateInfo.estateId}`);
      } else {
        return getS("fyb-current-estate-info");
      }
    },
    platformInfo(state) {
      //获取当前平台信息
      if (state.platformInfo) {
        return getS(
          `fyb-current-platform-info"${state.platformInfo.platformId}`
        );
      } else {
        return getS("fyb-current-platform-info");
      }
    }
  },
  mutations: {
    SET_USER_INFO(state, info) {
      //设置用户信息
      state.userInfo = info;
      setS("fyb-user-info", JSON.stringify(info));
    },
    SET_ESTATE_INFO(state, info) {
      //设置当前项目信息
      state.estateInfo = info;
      setS("fyb-current-estate-info", JSON.stringify(info));
      setS(`fyb-current-estate-info${info.estateId}`, JSON.stringify(info));
    },
    SET_PLATFORM_INFO(state, info) {
      //设置当前平台信息
      state.platformInfo = info;
      setS("fyb-current-platform-info", JSON.stringify(info));
      setS(
        `fyb-current-platform-info${info.platformId || ""}`,
        JSON.stringify(info)
      );
    },
    SET_PERMISSION_MENUS(state, info) {
      //设置权限菜单
      state.menuInfo = info;
      setS("fyb-permission-menus", JSON.stringify(info));
    },
    SET_OPENING_CAR_QUERY(state, info) {
      state.openingCarQuery = info;
    },
    SET_CAR_AREAS(state, info) {
      state.carAreas = info;
    },
    SET_REFRESH_FLAG(state) {
      state.refreshFlag = !state.refreshFlag;
    },
    SET_CAR_ACTIVE_ID(state, info) {
      state.carActiveId = info;
    }
  }
});
