<template>
  <el-container class="home">
    <navMenu v-if="!isMicroAppEnvironment" />
    <el-container class="container">
      <homeHeader v-if="!isMicroAppEnvironment" />
      <el-main class="main" :style="mainStyle">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import navMenu from "@/components/navMenu";
import homeHeader from "@/components/homeHeader";
export default {
  components: {
    navMenu,
    homeHeader
  },
  data() {
    return {
      // 是否在微应用环境中
      isMicroAppEnvironment: window.__MICRO_APP_ENVIRONMENT__
    };
  },
  computed: {
    mainStyle() {
      if (this.isMicroAppEnvironment) {
        return {
          padding: 0
        };
      }
      return undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  min-width: 1080px;
  overflow-x: auto;
  overflow-y: hidden;
  .container {
    height: 100%;
    flex-direction: column;
    position: relative;
  }
  .main {
    flex: 1;
    overflow-y: auto;
    background: #f5f5f5;
  }
}
</style>
