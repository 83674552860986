import store from "@/store";
export const microAppListener = () => {
  if (!window.__MICRO_APP_ENVIRONMENT__) return;
  // 返回主应用下发的data数据
  const data = window.microApp.getData();
  const { userInfo } = data || {};
  // 如果包含用户数据
  if (userInfo) {
    store.commit("SET_USER_INFO", userInfo);
    store.commit("SET_PERMISSION_MENUS", {
      baseMenus: userInfo.menuTree
    });
  }
};
