<template>
  <div class="platform-box">
    <div class="platform-info" v-if="platformType === 1">
      <img src="../../assets/logo.png" class="default-logo" />
    </div>
    <div class="platform-info" v-if="platformType === 2">
      <el-image fit="cover" :src="$estateInfo.estateLogo" class="logo">
        <i slot="error" class="el-icon-picture"></i>
      </el-image>
      <p class="text">
        {{ $estateInfo.estateName }}
      </p>
    </div>
    <div class="platform-info" v-if="platformType === 3 || platformType === 4">
      <el-image fit="cover" :src="$platformInfo.logo" class="logo">
        <i slot="error" class="el-icon-picture"></i>
      </el-image>
      <div class="text" v-if="$platformInfo.name">
        <p>{{ $platformInfo.name }}-{{ $platformInfo.applicationName }}</p>
        <p class="desc" v-if="platformType == 4">分销平台</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      platformType: 1
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.platformType = this.getPlatformType();
      }
    }
  },
  methods: {
    getPlatformType() {
      const { path } = this.$route;
      switch (true) {
        case /^\/estate\//.test(path):
          return 2;
        case /^\/platform\//.test(path):
          return 3;
        case /^\/distribution\//.test(path):
          return 4;
        default:
          return 1;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.platform-box {
  .platform-info {
    height: 70px;
    padding-left: 10px;
    @include flex-yc;
    .default-logo {
      height: 40px;
      margin-left: 10px;
    }
    .logo {
      width: 40px;
      min-width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 4px;
      font-size: 24px;
      color: #001529;
      background: #eee;
      @include flex-xyc;
    }
    .text {
      font-size: 18px;
      max-width: 130px;
      font-weight: bold;
      flex: 1;
      p {
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .desc {
        font-size: 14px;
        font-weight: normal;
        padding-top: 5px;
        opacity: 0.7;
      }
    }
    .switch-icon {
      width: 40px;
      min-width: 40px;
      height: 100%;
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      @include flex-xyc;
    }
  }
}
</style>
