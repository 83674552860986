var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-container', {
    staticClass: "message-router-view"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('f-platform-box'), _c('f-logout')], 1), _c('el-main', {
    staticClass: "main"
  }, [_c('router-view')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }