<template>
  <div class="aside-wrap">
    <div
      class="collapse-btn"
      :title="isCollapse ? '展开菜单' : '收起菜单'"
      @click="isCollapse = !isCollapse"
    >
      <i class="el-icon-s-unfold" v-if="isCollapse"></i>
      <i class="el-icon-s-fold" v-else></i>
    </div>
    <el-aside id="f-aside" class="navMenu" :class="{ collapse: isCollapse }">
      <div class="platform-info" v-if="systemType === 1">
        <img src="../../assets/logo.png" class="default-logo" />
      </div>
      <div class="platform-info" v-if="systemType === 2">
        <el-image fit="cover" :src="$estateInfo.estateLogo" class="logo">
          <i slot="error" class="el-icon-picture"></i>
        </el-image>
        <p class="text">
          {{ $estateInfo.estateName }}
        </p>
      </div>
      <div class="platform-info" v-if="systemType === 3 || systemType === 4">
        <el-image fit="cover" :src="$platformInfo.logo" class="logo">
          <i slot="error" class="el-icon-picture"></i>
        </el-image>
        <div class="text" v-if="$platformInfo.name">
          <p>{{ $platformInfo.name }}-{{ $platformInfo.applicationName }}</p>
          <p class="desc" v-if="systemType == 4">分销平台</p>
        </div>
        <el-popover
          placement="bottom"
          width="300"
          trigger="click"
          popper-class="switch-platform-popover"
          v-model="platformPopoverVisible"
          v-if="$userInfo.managerSite?.length > 1"
        >
          <switch-platform
            @close="platformPopoverVisible = false"
          ></switch-platform>
          <div class="switch-icon" slot="reference">
            <i class="el-icon-sort"></i>
          </div>
        </el-popover>
      </div>
      <el-menu
        ref="menu"
        :background-color="$styles.deepBlue"
        :text-color="$styles.white"
        :active-text-color="$styles.white"
        :default-active="defaultActive"
        :default-openeds="defaultOpeneds"
        router
        unique-opened
      >
        <template v-for="item in routerMenu">
          <el-submenu
            :index="item.path"
            v-if="item.children && item.children.length"
            :key="item.path"
          >
            <template slot="title">
              <i :class="`el-icon-${item.icon}`"></i>{{ item.name }}
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-show="showMenuItem(item2.path)"
                v-for="(item2, index2) in item.children"
                :index="item2.path"
                :key="index2"
                >{{ item2.name }}
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <template v-else>
            <el-menu-item
              :index="item.path"
              :key="item.path"
              :route="item.path"
            >
              <i :class="`el-icon-${item.icon}`"></i
              ><span slot="title">{{ item.name }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
import {
  estateRoutes,
  platformRoutes,
  distributionRoutes
} from "@/router/settings";
import { getMenu } from "@/api/setting";
import { flatJsonTree } from "@/utils";
import SwitchPlatform from "./_components/switchPlatform";
const DIS_HOME_ROUTE = {
  menuName: "首页",
  menuPath: "/distribution/home",
  menuIcon: "s-home"
};
export default {
  components: {
    SwitchPlatform
  },
  data() {
    return {
      isCollapse: false,
      routerMenu: [],
      defaultOpeneds: [],
      defaultActive: null,
      platformPopoverVisible: false, //控制切换弹窗平台显示隐藏
      systemType: 1 //系统类型 1工作台 2楼盘字典 3管理后台
    };
  },
  computed: {
    menuInfo() {
      //当前角色权限菜单
      return this.$store.state.menuInfo;
    }
  },
  watch: {
    $route(val) {
      this.getMenusByRoute(val);
    },
    isCollapse: {
      immediate: true,
      handler(val) {
        if (!val) {
          document.body.className += " menu-is-unfold";
        } else {
          document.body.className = document.body.className.replace(
            `menu-is-unfold`,
            ""
          );
        }
      }
    }
  },
  created() {
    this.getMenusByRoute(this.$route);
    this.setDefaultOpeneds();
  },
  methods: {
    showMenuItem(path) {
      const { applicationName } = this.$platformInfo;
      return (
        path != "/platform/appManage/activity2" ||
        (path == "/platform/appManage/activity2" &&
          applicationName?.includes("房猫找房"))
      );
    },
    // 默认展开当前子菜单的父级
    setDefaultOpeneds() {
      for (const submenu of this.routerMenu) {
        if (submenu?.children?.some(c => c.path === this.$route.path)) {
          this.defaultOpeneds = [submenu.path];
          break;
        }
      }
    },
    setDefaultActive() {
      this.defaultActive = this.$route.path;
    },
    handleMenusField(menus) {
      //处理菜单字段
      let result = this.$deepClone(menus);
      const map = menus => {
        for (let item of menus) {
          item.name = item.menuName;
          item.path = item.menuPath;
          item.icon = item.menuIcon;
          if (item.children && item.children.length) {
            map(item.children);
          }
        }
      };
      map(result);
      return result;
    },
    async getMenusByRoute(route) {
      //动态更改路由菜单数据
      if (JSON.stringify(this.routerMenu) == JSON.stringify(estateRoutes)) {
        return;
      }
      if (/^\/estate\//.test(route.path)) {
        //楼盘相关页面
        this.routerMenu = this.handleMenusField(estateRoutes);
        this.systemType = 2;
      } else if (/^\/platform\//.test(route.path)) {
        //管理后台相关页面
        this.routerMenu = this.handleMenusField(platformRoutes);
        this.systemType = 3;
      } else if (/^\/distribution\//.test(route.path)) {
        //分销平台相关页面
        let disRoutes;
        if (
          location.host.includes("localhost") ||
          location.host.includes("192.168")
        ) {
          disRoutes = this.$deepClone(distributionRoutes);
        } else {
          let res = await getMenu();
          disRoutes = res[0] && res[0].children ? res[0].children : [];
        }
        this.routerMenu = this.handleMenusField([DIS_HOME_ROUTE, ...disRoutes]);
        this.systemType = 4;
      } else {
        this.routerMenu = this.handleMenusField(this.menuInfo.baseMenus);
        this.systemType = 1;
      }
      this.setDefaultActive();
      this.judgeMenuAuth(route);
    },
    judgeMenuAuth(route) {
      // 如果没有该权限访问该页面(或者本身不存在该页面) 跳转到404
      // !route.path.includes(item.path) 用于判断一些页面的详情页或者编辑页
      // 如：/distribution/appManage/message 根据约定的路由命名规范，他一定拥有/distribution/appManage/message/:id的权限
      if (
        !flatJsonTree(this.routerMenu).some(
          item => item.path === route.path || !route.path.includes(item.path)
        )
      ) {
        this.$router.push("/404");
      } else {
        this.$nextTick(() => {
          this.$refs.menu.activeIndex = route.path;
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.navMenu {
  background-color: $deepBlue;
}
.platform-info {
  height: 70px;
  padding-left: 10px;
  @include flex-yc;
  .default-logo {
    height: 40px;
    margin-left: 10px;
  }
  .logo {
    width: 40px;
    min-width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 20px;
    font-size: 24px;
    color: #001529;
    background: #fff;
    @include flex-xyc;
  }
  .text {
    font-size: 18px;
    max-width: 130px;
    font-weight: bold;
    color: #fff;
    flex: 1;
    p {
      color: #fff;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .desc {
      font-size: 14px;
      font-weight: normal;
      padding-top: 5px;
      opacity: 0.7;
    }
  }
  .switch-icon {
    width: 40px;
    min-width: 40px;
    height: 100%;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    @include flex-xyc;
  }
}
</style>

<style lang="scss">
$primary: #4172f3;
.switch-platform-popover {
}
.aside-wrap {
  position: relative;
  background-color: #001529;
  z-index: 999;
  .collapse-btn {
    position: absolute;
    right: -40px;
    top: 0px;
    width: 40px;
    height: 60px;
    background: #ffffff;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    @include flex-xyc;
    &:active {
      background: #f5f5f5;
    }
  }
}
.navMenu {
  width: 220px !important;
  transition: width 0.3s ease-in-out;
  overflow: hidden;
  &.collapse {
    width: 0 !important;
  }
  .el-menu-item-group {
    padding: 0 10px;
    .el-menu-item {
      border-radius: 10px;
    }
  }
  .el-submenu {
    margin: 5px 0;
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      padding-left: 48px !important;
      padding-right: 48px !important;
      font-size: 14px !important;
    }
    &.is-active {
      .el-submenu__title {
        color: #fff !important;
        i {
          color: #fff !important;
        }
      }
    }
  }
  .el-submenu [class^="el-icon-"],
  .el-menu-item [class^="el-icon-"] {
    margin-right: 8px;
  }
  .el-submenu__title,
  .el-menu-item {
    height: 40px;
    line-height: 40px;
    color: rgba(255, 255, 255, 0.65) !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    font-size: 16px;
  }
  .el-menu-item:hover {
    color: #fff !important;
  }
  .el-menu-item.is-active {
    background: $primary !important;
    color: #fff !important;
  }

  .el-submenu__title i,
  .el-menu-item i {
    color: rgba(255, 255, 255, 0.65);
    font-size: 16px;
  }
  .el-submenu__icon-arrow {
    font-size: 12px !important;
    font-weight: bold;
  }
}
</style>
