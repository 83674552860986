const rules = {
  required(message, trigger = ["blur", "change"]) {
    return {
      required: true,
      trigger,
      message: message || "不能为空"
    };
  },
  pInt: {
    message: "必须为正整数",
    pattern: /^[1-9]\d*$/,
    trigger: "blur"
  },
  number: {
    message: "必须为正数",
    pattern: /^\d*(\.\d*)?$/,
    trigger: "blur"
  },
  int: {
    message: "必须为整数",
    pattern: /^\d*$/,
    trigger: "blur"
  },
  int2: {
    message: "必须为整数",
    pattern: /^-?\d*$/,
    trigger: "blur"
  },
  phoneAll: {
    pattern: /(^1\d{10}$)|(^400[0-9]{7})|(^800[0-9]{7})|(^(0[0-9]{2,3}-?)?([1-9][0-9]{6,7})+(-[0-9]{1,4})?$)/,
    message: "请输入正确的电话号码",
    trigger: "blur"
  },
  tel: {
    pattern: /^1\d{10}$/,
    message: "目前只支持中国大陆的手机号码",
    trigger: "blur"
  },
  phone: {
    pattern: /(^(0[0-9]{2,3}-?)?([1-9][0-9]{6,7})+(-[0-9]{1,4})?$)|(^1\d{10}$)|(^400-?\d{3}-?\d{4}$)/,
    message: "请输入正确的电话号码",
    trigger: "blur"
  },
  tel2: {
    pattern: /^(1\d{10}|1\d{6}\*{4}|1\d{2}\*{4}\d{4})$/, //匹配带星号的电话号码
    message: "目前只支持中国大陆的手机号码",
    trigger: "blur"
  },
  m0m29: {
    message: "范围0-99整数",
    pattern: /^\d{0,2}$/,
    trigger: "blur"
  },
  m0m29p2: {
    message: "范围0-99.99,仅保留两位小数",
    pattern: /^\d{0,2}?(\.\d{0,2})?$/,
    trigger: "blur"
  },
  m0m69: {
    message: "范围0-999999整数",
    pattern: /^\d{0,6}$/,
    trigger: "blur"
  },
  m0m69p2: {
    message: "范围0-999999.99,仅保留两位小数",
    pattern: /^\d{0,6}?(\.\d{0,2})?$/,
    trigger: "blur"
  },
  m0m89p2: {
    message: "范围0-99999999.99,仅保留两位小数",
    pattern: /^\d{0,8}?(\.\d{0,2})?$/,
    trigger: "blur"
  },
  m0m100p2: {
    message: "范围0-100.00,仅保留两位小数",
    pattern: /^(\d|[1-9]\d|100)(\.\d{1,2})?$/,
    trigger: "blur"
  },
  dnl3: {
    message: "1-3位字母或者数字",
    pattern: /^[a-zA-Z0-9]{1,3}$/,
    trigger: "blur"
  },
  dnl6: {
    message: "1-6位字母或者数字",
    pattern: /^[a-zA-Z0-9]{1,6}$/,
    trigger: "blur"
  },
  d6: {
    message: "请输入6位验证码",
    pattern: /^\d{6}$/,
    trigger: "blur"
  },
  idNum: {
    message: "身份证号格式不对",
    pattern: /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    trigger: "blur"
  },
  bankNum: {
    message: "银行卡号格式不对",
    pattern: /^([1-9]{1})([\d\s]{14,24})$/,
    trigger: "blur"
  },
  url: {
    message: "请输入正确的网址",
    pattern: /^(?:(http|https|ftp):\/\/)?((?:[\w-]+\.)+[a-z0-9]+)((?:\/[^/?#]*)+)?(\?[^#]+)?(#.+)?$/i,
    trigger: "blur"
  }
};

export default rules;
