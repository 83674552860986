import axios from "axios";
import { Message } from "element-ui";
import store from "@/store";
import { SUCCESS_CODE, BASE_URL, ERROR_MSG } from "@/config";
import { getRouteRootPathName, isEmpty } from "@/utils";
import qs from "qs";
import { showLoading, hideLoading as _hideLoading } from "./index";

let hideLoadingTimer = null;
const hideLoading = () => {
  clearTimeout(hideLoadingTimer);
  hideLoadingTimer = setTimeout(_hideLoading, 500);
};

let service = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json"
  }
});

const WHITELIST = [
  "/api/v1/fyb/web/user/login/getVerifyCode",
  "/api/v1/fyb/web/user/login/cellphoneLogin"
];

//请求开始
service.interceptors.request.use(
  config => {
    if (WHITELIST.indexOf(config.url) === -1) {
      // 把TOKEN放入请求头
      const { userId, token, estateId: estateId1 } = store.state.userInfo;
      const { estateId: estateId2 } = store.state.estateInfo;
      const { platformId } = store.state.platformInfo;
      const routeRootPathName = getRouteRootPathName();
      const estateId = estateId1 || estateId2;
      config.headers = {
        ...config.headers,
        userId,
        token,
        terminalSource: 4 // 终端来源：1-平台小程序  4-后台  7-IOS  8-Android
      };
      if (config.data?.showLoading !== false && config.showLoading !== false) {
        //显示加载动画
        showLoading();
      }
      if (Array.isArray(config.data) || Array.isArray(config.params)) {
        return config;
      }

      config.data = { ...config.data, userId, token };
      // 排除空值
      for (const key in config.data) {
        if (isEmpty(config.data[key])) {
          delete config.data[key];
        }
      }

      // 如果是楼盘管理 或者 开盘管理相关页面 接口参数携带上estateId
      // config.data.removeDefaultEstateId 是否移除默认传入的estateId
      if (
        ["estate", "opening"].includes(routeRootPathName) &&
        !isEmpty(estateId) &&
        !config.data.removeDefaultEstateId
      ) {
        config.headers = {
          ...config.headers,
          estateId
        };
        config.data = {
          ...config.data,
          estateId
        };
      }
      delete config.data.removeDefaultEstateId;
      // 如果是平台管理相关页面 接口参数携带上platformId
      if (
        ["platform", "distribution"].includes(routeRootPathName) &&
        !isEmpty(platformId)
      ) {
        config.headers = {
          ...config.headers,
          platformId
        };
        config.data = {
          ...config.data,
          platformId
        };
      }

      let requestMethod = config.method.toLocaleLowerCase();
      if (requestMethod == "get") {
        config.params = config.params || config.data;
        config.paramsSerializer = function(params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        };
      }
      if (requestMethod == "post" || requestMethod == "put") {
        config.data = config.data || {};
        config.paramsSerializer = function(params) {
          return qs.stringify(params, { arrayFormat: "repeat" });
        };
        config.data.token = token;
        config.data.userId = userId;
      }
      if (config.dataType == "formData") {
        //如果需要上传文件
        let formData = new FormData();
        for (let key in config.data) {
          if (Array.isArray(config.data[key])) {
            //如果是一个文件数组
            for (let item of config.data[key]) {
              formData.append(key, item);
            }
          } else {
            formData.append(key, config.data[key]);
          }
        }
        config.data = formData;
      }
    }
    delete config.data.showLoading;
    return config;
  },
  error => {
    hideLoading();
    Message.error(error);
    return false;
  }
);

//请求完成
service.interceptors.response.use(
  response => {
    hideLoading();
    if (response.config.originalData) {
      return response.data;
    }
    if (response.data.code !== SUCCESS_CODE) {
      //如果请求未成功
      if (response.config.showErrorMessage !== false) {
        Message.warning(
          response.data.message || response.data.errorMessage || ERROR_MSG
        );
      }
      return false;
    }
    return response.data.data !== null ? response.data.data : {};
  },
  error => {
    hideLoading();
    Message.error(error);
    return Promise.reject(error);
  }
);

function request(data) {
  if (!data.method) {
    data.method = "post";
  }
  return service(data);
}
export default request;
