// menuStatus: { "1": "展开", "2": "不展开", "3": "隐藏" }, //菜单类型
export const baseRoutes = [
  {
    menuName: "工作台",
    menuPath: "/workbench",
    menuIcon: "menu",
    menuStatus: 1,
    children: [
      {
        menuName: "楼盘项目",
        menuPath: "/workbench/estate",
        menuStatus: 2
      },
      {
        menuName: "聚合平台",
        menuPath: "/workbench/platform",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "企业设置",
    menuPath: "/setting",
    menuIcon: "s-tools",
    menuStatus: 1,
    children: [
      {
        menuName: "企业信息",
        menuPath: "/setting/info",
        menuStatus: 2
      },
      {
        menuName: "管理员设置",
        menuPath: "/setting/admin",
        menuStatus: 2
      }
    ]
  }
];

//楼盘相关路由
export const estateRoutes = [
  {
    menuName: "楼盘字典",
    menuPath: "/estate/dictionary",
    menuIcon: "menu",
    menuStatus: 1,
    children: [
      {
        menuName: "基础信息",
        menuPath: "/estate/dictionary/baseInfo",
        menuStatus: 2
      },
      {
        menuName: "户型管理",
        menuPath: "/estate/dictionary/houseType",
        menuStatus: 2
      },
      {
        menuName: "相册管理",
        menuPath: "/estate/dictionary/album",
        menuStatus: 2
      },
      {
        menuName: "视频管理",
        menuPath: "/estate/dictionary/video",
        menuStatus: 2
      },
      {
        menuName: "文章管理",
        menuPath: "/estate/dictionary/article",
        menuStatus: 2
      },
      {
        menuName: "评论问答",
        menuPath: "/estate/dictionary/comment",
        menuStatus: 2
      },
      {
        menuName: "动态管理",
        menuPath: "/estate/dictionary/trends",
        menuStatus: 2
      },
      {
        menuName: "购房优惠",
        menuPath: "/estate/dictionary/coupon",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "选房认购",
    menuPath: "/estate/subscription",
    menuStatus: 1,
    menuIcon: "s-goods",
    children: [
      {
        menuName: "网上开盘",
        menuPath: "/opening/index",
        menuStatus: 2
      },
      {
        menuName: "认购订单",
        menuPath: "/estate/subscription/order",
        menuStatus: 2
      },
      {
        menuName: "认购公示",
        menuPath: "/estate/subscriptionFormula",
        menuStatus: 2
      },
      {
        menuName: "营销活动",
        menuPath: "/estate/marketing",
        menuStatus: 2
      },
      {
        menuName: "预约登记",
        menuPath: "/estate/appointmentRegistration",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "分销设置",
    menuPath: "/estate/distribution",
    menuStatus: 1,
    menuIcon: "s-tools",
    children: [
      {
        menuName: "规则须知",
        menuPath: "/estate/distribution/rule",
        menuStatus: 2
      },
      {
        menuName: "项目资料",
        menuPath: "/estate/distribution/project",
        menuStatus: 2
      },
      {
        menuName: "渠道管理",
        menuPath: "/estate/distribution/channel",
        menuStatus: 2
      },
      {
        menuName: "佣金方案",
        menuPath: "/estate/distribution/programme",
        menuStatus: 2
      },
      {
        menuName: "带看奖励",
        menuPath: "/estate/distribution/reward",
        menuStatus: 2
      },
      {
        menuName: "报备带看",
        menuPath: "/estate/distribution/report",
        menuStatus: 2
      }
    ]
  }
];

//管理后台路由
export const platformRoutes = [
  {
    menuName: "楼盘管理",
    menuPath: "/platform/estate",
    menuIcon: "office-building",
    menuStatus: 1,
    children: [
      {
        menuName: "关联楼盘",
        menuPath: "/platform/estate/correl",
        menuStatus: 2
      },
      {
        menuName: "推荐楼盘",
        menuPath: "/platform/estate/recommend",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "访客管理",
    menuPath: "/platform/visitor",
    menuIcon: "user-solid",
    menuStatus: 1,
    children: [
      {
        menuName: "访客概要",
        menuPath: "/platform/visitor/outline",
        menuStatus: 2
      },
      {
        menuName: "用户认证",
        menuPath: "/platform/visitor/auth",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "应用管理",
    menuPath: "/platform/appManage",
    menuIcon: "s-order",
    menuStatus: 1,
    children: [
      {
        menuName: "文章管理",
        menuPath: "/platform/appManage/article",
        menuStatus: 2
      },
      {
        menuName: "问答管理",
        menuPath: "/platform/appManage/question",
        menuStatus: 2
      },
      {
        menuName: "榜单管理",
        menuPath: "/platform/appManage/rank",
        menuStatus: 2
      },
      {
        menuName: "管理挑房",
        menuPath: "/platform/appManage/tf",
        menuStatus: 2
      },
      {
        menuName: "直播活动",
        menuPath: "/platform/appManage/live",
        menuStatus: 2
      },
      {
        menuName: "广告管理",
        menuPath: "/platform/appManage/banner",
        menuStatus: 2
      },
      {
        menuName: "找房委托",
        menuPath: "/platform/appManage/housingCommission",
        menuStatus: 2
      },
      {
        menuName: "看房专车",
        menuPath: "/platform/appManage/specialCar",
        menuStatus: 2
      },
      {
        menuName: "评论管理",
        menuPath: "/platform/appManage/activity",
        menuStatus: 2
      },
      {
        menuName: "天府里·188万活动",
        menuPath: "/platform/appManage/activity2",
        menuStatus: 2
      }
    ]
  }
];

//分销平台路由
export const distributionRoutes = [
  // navMenu组件内默认展示
  // {
  //   menuName: "首页",
  //   menuPath: "/distribution/home",
  //   menuIcon: "s-home"
  // },
  {
    menuName: "楼盘管理",
    menuPath: "/distribution/estate",
    menuIcon: "office-building",
    menuStatus: 1,
    children: [
      {
        menuName: "分销楼盘",
        menuPath: "/distribution/estate/distribution",
        menuStatus: 2
      },
      {
        menuName: "推荐楼盘",
        menuPath: "/distribution/estate/recommend",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "用户管理",
    menuPath: "/distribution/userManage",
    menuIcon: "user-solid",
    menuStatus: 1,
    children: [
      {
        menuName: "访问概要",
        menuPath: "/distribution/userManage/outline",
        menuStatus: 2
      },
      {
        menuName: "注册用户",
        menuPath: "/distribution/userManage/registeredUser",
        menuStatus: 2
      },
      {
        menuName: "渠道管理",
        menuPath: "/distribution/userManage/channel",
        menuStatus: 2
      },
      {
        menuName: "用户认证",
        menuPath: "/distribution/userManage/auth",
        menuStatus: 2
      },
      {
        menuName: "管理员设置",
        menuPath: "/distribution/userManage/admin",
        menuStatus: 2
      }
    ]
  },
  {
    menuName: "应用管理",
    menuPath: "/distribution/appManage",
    menuStatus: 1,
    menuIcon: "s-order",
    children: [
      {
        menuName: "报备带看",
        menuPath: "/distribution/appManage/report",
        menuStatus: 2
      },
      {
        menuName: "广告管理",
        menuPath: "/distribution/appManage/banner",
        menuStatus: 2
      },
      {
        menuName: "群发消息",
        menuPath: "/distribution/appManage/message",
        menuStatus: 2
      },
      {
        menuName: "房源销控",
        menuPath: "/distribution/appManage/houseSalesControl",
        menuStatus: 2
      },
      {
        menuName: "甲方结算",
        menuPath: "/distribution/appManage/settlement",
        menuStatus: 2
      },
      {
        menuName: "认购签约",
        menuPath: "/distribution/appManage/signedOrder",
        menuStatus: 2
      },
      {
        menuName: "成交管理",
        menuPath: "/distribution/appManage/transactionManage",
        menuStatus: 2
      }
    ]
  }
];
