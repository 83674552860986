<template>
  <div class="container">
    <div class="head">
      <h2>活动参与列表</h2>
      <div class="filter">
        <el-select
          size="small"
          v-model="queryData.commentType"
          @change="search"
        >
          <el-option
            v-for="item of activityList"
            :key="item.type"
            :value="item.type"
            :label="item.name"
          ></el-option>
        </el-select>
        <el-radio-group
          size="small"
          v-model="queryData.accepted"
          @change="search('accepted')"
        >
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button label="1">已中奖</el-radio-button>
          <!-- <el-radio-button label="0">未中奖</el-radio-button> -->
        </el-radio-group>
        <el-date-picker
          class="w250"
          size="small"
          type="daterange"
          v-model="queryData.inDate"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="search('date')"
        ></el-date-picker>
        <el-input
          placeholder="内容关键字"
          size="small"
          class="w320"
          v-model="queryData.keyword"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="search"
          ></el-button>
        </el-input>
      </div>
    </div>
    <div class="body">
      <el-table border :data="list">
        <el-table-column label="用户信息" width="200">
          <template v-slot="{ row }">
            <div class="user">
              <el-image class="avatar" :src="row.customerAvatarPath"></el-image>
              <div class="txt">
                <p>{{ row.customerName }}</p>
                <span>{{ row.distributionMechanismName }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="是否选中"
          width="180"
          v-if="queryData.commentType == 24"
        >
          <template v-slot="{ row }">
            <div v-if="row.replyType == 10">
              <span class="green">是</span>
              <p>选中手机：{{ row.distributionUserPhone || "-" }}</p>
            </div>
            <p v-else>否</p>
          </template>
        </el-table-column>
        <el-table-column label="是否中奖" width="180">
          <template v-slot="{ row }">
            <div v-if="row.accepted">
              <span class="green">是</span>
              <p>中奖编号：{{ row.acceptNo || "-" }}</p>
              <p>中奖手机：{{ row.distributionUserPhone || "-" }}</p>
            </div>
            <p v-else>否</p>
          </template>
        </el-table-column>
        <el-table-column label="评论内容">
          <template v-slot="{ row }">
            <div
              class="comment-content"
              v-html="row.content.replace(/\n/g, '<br>')"
            ></div>
            <div class="images" v-if="row.attachs && row.attachs.length">
              <el-image
                fit="cover"
                v-for="item of row.attachs"
                :src="item.fileUrl"
                :key="item.fileUrl"
                :preview-src-list="row.attachs.map(item => item.fileUrl)"
              ></el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="评论时间" width="160">
          <template v-slot="{ row }">
            {{ $time(row.inDate).format("yyyy-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column label="是否重复" width="100">
          <template v-slot="{ row }">
            <p>
              <span class="red" v-if="row.repeat">是</span
              ><span v-else>否</span>
            </p>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="90"
          v-if="queryData.commentType != 23"
        >
          <template v-slot="{ row }">
            <el-link
              v-if="queryData.commentType == 24"
              :type="row.replyType == 10 ? 'danger' : 'primary'"
              @click="changeAccept(row)"
              >{{ row.replyType == 10 ? "取消选中" : "设为选中" }}</el-link
            >
            <template
              v-if="
                queryData.commentType != 24 ||
                  (queryData.commentType == 24 && row.replyType == 10)
              "
            >
              <el-link
                v-if="!row.accepted"
                type="primary"
                @click="changeAccept(row)"
                >设为中奖</el-link
              >
              <el-link v-else type="danger" @click="changeAccept(row)"
                >取消中奖</el-link
              >
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <f-pagination
      fixed
      :page-num.sync="queryData.pageNum"
      :page-size.sync="queryData.pageSize"
      :total="total"
      @change="getTempActivityCommentList"
    ></f-pagination>
  </div>
</template>

<script>
import {
  getTempActivityCommentList,
  acceptComment,
  cancelAcceptedComment,
  getActivityTypeList,
  acceptVote
} from "@/api/activity";
export default {
  data() {
    return {
      queryData: {
        accepted: "", // 1中奖
        bizId: 6515,
        pageNum: 1,
        pageSize: 10,
        commentType: "",
        keyword: "",
        inDate: [],
        startDate: "",
        endDate: ""
      },
      total: 0,
      list: [],
      activityList: [],
      estateMap: {
        "/temp/activity/yuanqili": 6515,
        "/temp/activity/tianfuyinghui": 67,
        "/temp/activity/ersanli": 6850
      }
    };
  },
  created() {
    this.handleAccess();
    this.queryData.bizId = this.estateMap[this.$route.path];
    this.getActivityTypeList();
    // 默认昨天
    const yesterday = this.$time()
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    this.queryData.inDate = [yesterday, yesterday];
  },
  methods: {
    // 处理页面访问权限
    handleAccess() {
      // 登录时候注入的activityPath
      const { activityPath } = this.$userInfo;
      const { query, path } = this.$route;
      if (activityPath !== path && !query.isAdmin) {
        this.$router.replace(`/404`);
      }
    },
    async getActivityTypeList() {
      const res = await getActivityTypeList();
      if (res) {
        this.activityList = res.filter(
          item => item.estateId === this.queryData.bizId
        );
        this.queryData.commentType = this.activityList[0]?.type;
        this.queryData.commentType && this.search("date");
      }
    },
    search(type) {
      if (type === "date") {
        const [start, end] = this.queryData.inDate || [];
        this.queryData.startDate = start;
        this.queryData.endDate = end;
      }
      // 贰叁里抽奖送购物卡活动已中奖的字段 特殊处理
      if (
        type === "accepted" &&
        this.queryData.commentType == 23 &&
        this.queryData.accepted == 1
      ) {
        this.queryData.replyType = 3;
      } else {
        this.queryData.replyType = null;
      }
      this.queryData.pageNum = 1;
      this.getTempActivityCommentList();
    },
    async changeAccept(row) {
      const res = await (this.queryData.commentType == 24
        ? acceptVote({ id: row.id, type: row.replyType == 10 ? 1 : 10 })
        : row.accepted
        ? cancelAcceptedComment(row.id)
        : acceptComment(row.id));
      if (res) {
        this.getTempActivityCommentList();
      }
    },
    async getTempActivityCommentList() {
      const res = await getTempActivityCommentList(this.queryData);
      if (res) {
        this.list = res.list;
        this.total = res.total;
      }
    }
  }
};
</script>

<style scoped lang="scss">
.w250 {
  width: 250px;
}
.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .head {
    padding: 20px;
    .filter {
      padding-top: 20px;
      @include flex-yc;
      & > div {
        margin-right: 10px;
      }
    }
  }
  .body {
    padding: 10px 20px 20px;
    flex: 1;
    overflow-y: auto;
    .user {
      @include flex-yc;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 20px;
      }
      .txt {
        padding-left: 10px;
        span {
          padding-top: 10px;
          color: #999;
        }
      }
    }
  }
  .comment-content {
    ::v-deep {
      .title {
        font-size: 12px;
        color: #999;
      }
    }
  }
  .images {
    padding-top: 10px;
    gap: 10px;
    @include flex-w;
    ::v-deep {
      .el-image {
        width: 100px;
        height: 100px;
        border: 1px solid #eee;
      }
    }
  }
}
</style>
