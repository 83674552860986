import Vue from "vue";
import { keepDecimal } from "@/utils/calc";
import { format } from "@/utils";

// 格式化时间
Vue.filter("formatDate", (str, type = 2) => {
  return format.date(str, type);
});

/**
 * 格式化数字等，以千分号隔开
 **/

Vue.filter("toThousands", num => {
  /**
   * 格式化数字等，以千分号隔开
   * @String | Number : num
   **/
  if (typeof num === "object") {
    if (!num) {
      num = "";
    }
  }
  if (typeof num === "undefined") {
    return;
  }
  num = String(num).split(".");
  let arr = num[0].split("").reverse();
  let res = [];
  for (var i = 0; i < arr.length; i++) {
    if (i % 3 == 0 && i != 0) {
      res.push(",");
    }
    res.push(arr[i]);
  }
  res.reverse();
  if (num[1]) {
    res = res.join("").concat("." + num[1]);
  } else {
    res = res.join("");
  }
  return res.replace("-,", "-");
});

Vue.filter("toW", num => {
  /*
   *以万为单位 最多保留两位小数
   */
  if (isNaN(Number(num)) || Number(num) < 10000) return num;
  let res = (Number(num) / 10000).toFixed(2).toString();
  if (res.slice(-2) == "00") {
    return res.slice(0, -3);
  } else if (res.slice(-1) == "0") {
    return res.slice(0, -1);
  }
  return res;
});

Vue.filter("formatPhone", format.phone);

Vue.filter("formatGender", str => {
  /*
   *先生OR女士
   */
  let gender = str;
  switch (Number(str)) {
    case 1:
      gender = "先生";
      break;
    case 2:
      gender = "女士";
      break;
    default:
      gender = "";
  }
  return gender;
});

Vue.filter("converDate", obj => {
  /**
   *计算时间
   *@String | Number : obj 时间戳或者标准时间格式字符串
   **/
  if (!obj) {
    return;
  }
  const conver = num => {
    //补0
    return num < 10 ? `0${num}` : num;
  };
  console.log(obj);
  let str = typeof obj == "string" ? obj.replace(/-/g, "/") : obj;
  const bDate = new Date(str); //需要判断的时间
  const aDate = new Date(); //当前时间
  const bYear = bDate.getFullYear();
  const bMonth = bDate.getMonth() + 1;
  const bDay = bDate.getDate();
  const bHour = conver(bDate.getHours());
  const bMin = conver(bDate.getMinutes());
  const aYear = aDate.getFullYear();
  const aMonth = aDate.getMonth() + 1;
  const aDay = aDate.getDate();

  if (bYear == aYear && bMonth == aMonth && bDay == aDay) {
    //今天
    return `${bHour}:${bMin}`;
  }
  if (bYear == aYear) {
    //今年
    return `${conver(bMonth)}月${conver(bDay)}日`;
  }
  //其他年
  return `${bYear}年${conver(bMonth)}月${conver(bDay)}日`;
});

Vue.filter("isEmpty", obj => {
  /*
   *为空时返回 ‘暂无数据’
   */
  if ((typeof obj == "object" && !obj) || obj === "") {
    //判断对象是null 或者 ''空串
    return "暂无数据";
  }
  return obj;
});

Vue.filter("isEmpty2", obj => {
  /*
   *为空时返回 ‘暂无数据’
   */
  if ((typeof obj == "object" && !obj) || obj === "") {
    //判断对象是null 或者 ''空串
    return "-";
  }
  return obj;
});

/*
 *保留两位小数
 */
Vue.filter("toFix2", keepDecimal);

Vue.filter("toIntCeil", num => {
  /*
   *向上取整 保留整数
   */
  if (typeof num == "number") {
    return Math.ceil(num);
  }
  if (typeof num == "string") {
    if (!isNaN(Number(num))) {
      return Math.ceil(num);
    }
  }
  return num;
});

Vue.filter("toInt", num => {
  /*
   *保留整数
   */
  if (typeof num == "number") {
    return parseInt(num);
  }
  if (typeof num == "string") {
    if (!isNaN(Number(num))) {
      return parseInt(num);
    }
  }
  return num;
});

Vue.filter("link", (arr, separator = "-") => {
  /**
   * 短线关联存在数据，例如：
   * 店组： 店 ; 店-组
   * @Array 需要处理的数据的数组集合
   **/
  if (!arr) return "";
  let str = "";
  arr = arr.filter(e => e);
  str = arr.join(separator);
  return str;
});

Vue.filter("cardNumber", (str, type = 1) => {
  /**
   * 格式化身份证号码
   * **/
  if (typeof str !== "string" && typeof str !== "number") return str;
  str = String(str);
  // 每四位以空格隔开
  if (type === 1) {
    let res = "";
    for (let i = 0; i < str.length; i++) {
      res += str[i];
      if (!((i + 1) % 4)) {
        res += " ";
      }
    }
    return res;
    // 前三 后四星
  } else if (type === 2) {
    return str.replace(/^([a-zA-Z0-9]{3})([a-zA-Z0-9]+)$/, `$1 ****`);
  }
  return str;
});
