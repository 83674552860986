var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-header', {
    staticClass: "homeHeader x3 aic"
  }, [_c('div', {
    staticClass: "fs24 color3 bold"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('logout')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }