<template>
  <div class="f-pagination-wrapper" :class="{ fixed, shadow }">
    <div class="f-pagination">
      <el-pagination
        ref="pagination"
        prev-text="上一页"
        next-text="下一页"
        layout="total, sizes, prev, pager, next, jumper"
        v-bind="$attrs"
        v-on="$listeners"
        :total="total"
        :current-page.sync="pageNum_"
        :page-size="pageSize"
        :page-sizes="pageSizes"
        @size-change="handleChange($event, 'pageSize')"
        @current-change="handleChange($event, 'pageNum')"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 组件名字 用于缓存
    name: {
      type: String,
      default: ""
    },
    fixed: {
      // 是否固定到底部
      type: Boolean,
      default: true
    },
    // 非固定时是否显示阴影
    shadow: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    pageNum: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 0
    },
    pageSizes: {
      type: Array,
      default: () => [5, 10, 20, 30, 40, 50, 100]
    }
  },
  data() {
    return {
      pageNum_: 1
    };
  },
  watch: {
    total: {
      immediate: true,
      handler() {
        this.handlePaginationTotalHTML();
      }
    },
    pageNum: {
      immediate: true,
      handler(val) {
        this.pageNum_ = val;
      }
    }
  },
  methods: {
    // 调整total html的样式
    handlePaginationTotalHTML() {
      this.$nextTick(() => {
        const elPagination = this.$refs.pagination.$el;
        const elTotal = elPagination.querySelector(".el-pagination__total");
        elTotal.innerHTML = `共<span class="f-pagination__total">${this.total}</span>条`;
      });
    },
    // 当前页数 页面总数 变化时
    handleChange(num, type) {
      let pageNum = this.pageNum_;
      let pageSize = this.pageSize;
      if (type === "pageNum") {
        pageNum = num;
        this.$emit("update:pageNum", num);
      } else {
        pageSize = num;
        if (this.name) {
          this.$storage.set(this.name + "PageSize", num);
        }
        this.$emit("update:pageSize", num);
      }
      this.$emit("change", { pageNum, pageSize });
    }
  }
};
</script>

<style scoped lang="scss">
.f-pagination-wrapper {
  height: 50px;
  z-index: 1;
  .f-pagination {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &.fixed {
    .f-pagination {
      position: fixed;
      padding-left: 200px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 99;
      background: #fff;
      box-shadow: 0px -4px 16px 0px rgba(9, 1, 3, 0.11);
    }
  }
  &.shadow {
    box-shadow: 0px -4px 16px 0px rgba(9, 1, 3, 0.11);
  }
  ::v-deep {
    .el-pagination {
      .f-pagination__total {
        text-align: center;
        font-weight: bold;
        color: #f56c6c;
      }
    }
  }
}
</style>
