<template>
  <el-header class="homeHeader x3 aic">
    <div class="fs24 color3 bold">
      {{ title }}
    </div>
    <logout />
  </el-header>
</template>

<script>
import logout from "@/components/logout";
import {
  estateRoutes,
  platformRoutes,
  distributionRoutes
} from "@/router/settings";
import { flatJsonTree } from "@/utils";
export default {
  components: {
    logout
  },
  computed: {
    baseRoutes() {
      return this.$store.state.menuInfo.baseMenus || [];
    },
    routes() {
      return flatJsonTree([
        ...this.baseRoutes,
        ...estateRoutes,
        ...platformRoutes,
        ...distributionRoutes
      ]);
    },
    title() {
      const find = this.routes.find(item => item.menuPath == this.$route.path);
      if (find) {
        return find.menuName;
      } else {
        // 找不到匹配的就找相关的（用于详情页：pages/page2/page3  pages/page2/page3/:id）
        const associated = this.routes.find(
          item =>
            item.menuPath ==
            this.$route.path.replace(/\/((?:[^/]+?))(?:\/(?=$))?$/, "")
        );
        return associated ? associated.menuName : "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.homeHeader {
  padding-left: 50px;
  background-color: #ffffff;
  height: 60px;
  // border-bottom: 1px solid $bdColor;
}
</style>
