import CryptoJS from "crypto-js";

// key iv不要随意改编码规则，因为PCN也在用，不然跳转过来解密会失败
const key = CryptoJS.enc.Utf8.parse("FAFAFA9832151234"); //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse("983215FAFAFA3456"); //十六位十六进制数作为密钥偏移量

const params = {
  iv,
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7
};

//解密方法
function decrypt(word, isCatch = true) {
  const fn = () => {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(srcs, key, params);
    return decrypt.toString(CryptoJS.enc.Utf8);
  };
  // 是否捕获异常
  if (isCatch) {
    try {
      const res = fn();
      return res ? res : word;
    } catch {
      return word;
    }
  }
  const res = fn();
  return res ? res : word;
}

//加密方法
function encrypt(word, isCatch = true) {
  const fn = () => {
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, params);
    return encrypted.ciphertext.toString();
  };
  // 是否捕获异常
  if (isCatch) {
    try {
      const res = fn();
      return res ? res : word;
    } catch {
      return word;
    }
  }
  const res = fn();
  return res ? res : word;
}

window.toolbar = {
  ...(window.toolbar || {}),
  de: decrypt,
  en: encrypt
};
export { decrypt, encrypt };
