var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dropdown', [_c('div', {
    staticClass: "account-bar"
  }, [_c('img', {
    staticClass: "avatar",
    attrs: {
      "src": _vm.userInfo.avatarPath || _vm.$defaultAvatarUrl
    }
  }), _c('p', {
    staticClass: "name"
  }, [_vm._v(" " + _vm._s(_vm.userInfo.nickName || _vm.userInfo.realName || _vm.userInfo.userName || "暂无信息") + " ")]), _c('i', {
    staticClass: "el-icon-caret-bottom"
  })]), _c('el-dropdown-menu', {
    attrs: {
      "slot": "dropdown"
    },
    slot: "dropdown"
  }, [_c('el-dropdown-item', {
    nativeOn: {
      "click": function click($event) {
        return _vm.logout.apply(null, arguments);
      }
    }
  }, [_vm._v("退出登录")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }