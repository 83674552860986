<template>
  <el-table ref="table" v-bind="$attrs" v-on="$listeners">
    <template v-for="(item, index) in columns">
      <el-table-column
        v-if="item.type === 'selection'"
        :key="index"
        v-bind="item"
      ></el-table-column>
      <el-table-column v-else :key="index" v-bind="item">
        <template slot-scope="scope">
          <c-render
            v-if="item.render"
            :scope="scope"
            :render="item.render"
            :tag="item.renderTag"
          ></c-render>
          <span
            :style="item.style"
            :class="item.class"
            v-else-if="item.isComma"
          >
            <span v-if="scope.row[item.prop] || scope.row[item.prop] == 0"
              >{{ item.isPrice ? "¥" : ""
              }}{{ scope.row[item.prop] | toThousands }}</span
            >
            <span v-else>{{ item.placeholder || "-" }}</span>
          </span>
          <span v-else-if="item.isDate && scope.row[item.prop]">
            {{ $format.date(scope.row[item.prop], item.dateType) }}
          </span>
          <span :style="item.style" :class="item.class" v-else
            >{{ scope.row[item.prop] || item.placeholder || "" }}
          </span>
        </template>
      </el-table-column>
    </template>
  </el-table>
</template>

<script>
import CRender from "./_components/render.vue";
export default {
  components: {
    CRender
  },
  props: {
    /*
    Array<ElTableColumnProps  & 
    {render: (h, param), 
    isPrice?:boolean, 
    isComma?:boolean, 
    isDate?:boolean,
    dateType?:number,
    style?:string, 
    placeholder?:string, 
    renderTag?:string}>
    */
    columns: { type: Array, default: () => [] }
  },
  watch: {
    columns: {
      immediate: true,
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          // 列数据更新重新布局时候 调用此方法 防止表头闪烁
          this.$refs.table.doLayout();
        });
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
