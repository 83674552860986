<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { baseRoutes } from "@/router/settings";
export default {
  created() {
    this.initData();
  },
  methods: {
    initData() {
      //初始化用户相关数据
      if (location.hostname == "localhost") {
        this.$store.commit("SET_PERMISSION_MENUS", {
          baseMenus: baseRoutes
        });
      }
    },
    getQueryVariable() {
      let href = window.location.href;
      let query = href.substring(href.indexOf("?") + 1);
      let vars = query.split("&");
      let obj = {};
      for (var i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        obj[pair[0]] = pair[1];
      }
      return obj;
    }
  }
};
</script>
<style lang="scss" scoped>
#app {
  width: 100%;
  height: 100%;
}
</style>
