var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "platform-box"
  }, [_c('h3', [_vm._v("选择你要管理的运营平台")]), _c('div', {
    staticClass: "list"
  }, _vm._l(_vm.list.slice(0, 5), function (item) {
    return _c('div', {
      key: item.id,
      staticClass: "item",
      on: {
        "click": function click($event) {
          return _vm.handleClick(item);
        }
      }
    }, [_c('el-image', {
      staticClass: "logo",
      attrs: {
        "src": item.logo,
        "fit": "cover"
      }
    }, [_c('i', {
      staticClass: "el-icon-picture-outline",
      attrs: {
        "slot": "error"
      },
      slot: "error"
    })]), _c('div', {
      staticClass: "txt"
    }, [_c('p', [_vm._v(_vm._s(item.applicationName) + "·" + _vm._s(item.name))]), _c('span', [_vm._v(_vm._s(_vm.typeLabelMap[item.type]))])]), _c('i', {
      staticClass: "el-icon-arrow-right"
    })], 1);
  }), 0), _c('div', {
    staticClass: "more"
  }, [_vm.list.length > 5 ? _c('el-link', {
    attrs: {
      "underline": false
    },
    on: {
      "click": function click($event) {
        return _vm.handleClick();
      }
    }
  }, [_vm._v("更多平台")]) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }