var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isExternal ? _c('div', _vm._g({
    staticClass: "svg-external-icon svg-icon",
    style: Object.assign({}, _vm.styleExternalIcon, {
      color: _vm.color
    })
  }, _vm.$listeners)) : _c('svg', _vm._g({
    class: _vm.svgClass,
    style: {
      color: _vm.color
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, _vm.$listeners), [_c('use', {
    attrs: {
      "xlink:href": _vm.iconName
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }