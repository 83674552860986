var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-popover', {
    attrs: {
      "placement": "top-start",
      "title": "",
      "width": "240",
      "trigger": "click"
    }
  }, [_c('div', {
    attrs: {
      "slot": "reference"
    },
    slot: "reference"
  }, [_vm._t("default")], 2), _c('div', [_c('el-input', {
    attrs: {
      "size": "small",
      "suffix-icon": "el-icon-search",
      "placeholder": "查找字段 按Enter键确认"
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleSearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.keyword,
      callback: function callback($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  }), _c('div', {
    staticClass: "tree-wrap"
  }, [_vm._l(_vm.displayFields, function (group, index) {
    return [typeof group === 'string' && group ? _c('p', {
      key: group,
      staticClass: "group-title"
    }, [_vm._v(" " + _vm._s(group) + " ")]) : group.length ? _c('el-tree', {
      key: group.prop,
      attrs: {
        "data": group,
        "node-key": "prop",
        "draggable": _vm.displayFields.length === _vm.options.length && group.length === _vm.options[index].length,
        "allow-drop": _vm.allowDrop,
        "allow-drag": _vm.allowDrag
      },
      on: {
        "node-drop": _vm.handleNodeDrop
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref) {
          var node = _ref.node,
            data = _ref.data;
          return _c('span', {
            staticClass: "custom-tree-node",
            class: {
              disabled: data.disabled
            }
          }, [_c('i', {
            staticClass: "iconfont icon-drag-dot"
          }), _c('span', {
            staticClass: "label"
          }, [_vm._v(_vm._s(node.label))]), _c('el-switch', {
            attrs: {
              "disabled": data.disabled
            },
            on: {
              "change": function change($event) {
                return _vm.handleSwitch(index, data);
              }
            },
            model: {
              value: data.checked,
              callback: function callback($$v) {
                _vm.$set(data, "checked", $$v);
              },
              expression: "data.checked"
            }
          })], 1);
        }
      }], null, true)
    }) : _vm._e()];
  })], 2), _c('div', {
    staticClass: "actions"
  }, [_c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v("恢复默认")]), _c('el-button', {
    attrs: {
      "size": "small"
    },
    on: {
      "click": _vm.openAll
    }
  }, [_vm._v("显示所有")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }