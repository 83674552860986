import request from "@/utils/request";

// 分页查询楼盘列表
export const getEstatePage = data =>
  request({ url: `/api/v1/estate/manage/getEstatePage`, data });

// 创建楼盘
export const createEstate = data =>
  request({ url: `/api/v1/estate/manage/createEstate`, data });

// 编辑楼盘
export const editEstateBase = data =>
  request({ url: `/api/v1/estate/manage/editEstateBase`, data });

// 可见范围及状态设置
export const editVisiable = data =>
  request({ url: `/api/v1/estate/manage/editVisiable`, data });

// 查询楼盘基础信息详情
export const getEstateDetail = (data, showErrorMessage) =>
  request({
    url: `/api/v1/estate/manage/getEstateDetail`,
    data,
    showErrorMessage
  });

// 查询系统预定义的楼盘特点
export const getSystemCharacteris = data =>
  request({
    url: `/api/v1/estate/manage/getSystemCharacteris`,
    data
  });

// 查询系统预定义的建筑类型
export const getBuildingTypeEnum = data =>
  request({
    url: `/api/v1/estate/manage/getBuildingTypeEnum`,
    data
  });

// 查询楼盘亮点列表
export const getEstateSellPointList = data =>
  request({
    url: `/api/v1/estate/manage/getEstateSellPointList`,
    data
  });

// 创建楼盘亮点
export const createEstateSellPoint = data =>
  request({
    url: `/api/v1/estate/manage/createEstateSellPoint`,
    data
  });

// 编辑楼盘亮点
export const editEstateSellPoint = data =>
  request({
    url: `/api/v1/estate/manage/editEstateSellPoint`,
    data
  });

// 删除楼盘亮点
export const deleteEstateSellPoint = data =>
  request({
    url: `/api/v1/estate/manage/deleteEstateSellPoint`,
    data
  });

// 查询楼盘价格信息列表
export const getPriceList = data =>
  request({
    url: `/api/v1/estate/price/getPriceList`,
    data
  });

// 查询楼盘价格信息详情
export const getPriceDetail = data =>
  request({
    url: `/api/v1/estate/price/getPriceDetail`,
    data
  });

// 删除价格信息
export const deletePrice = data =>
  request({
    url: `/api/v1/estate/price/deletePrice`,
    data
  });

// 创建开盘信息
export const createPrice = data =>
  request({
    url: `/api/v1/estate/price/createPrice`,
    data
  });

// 编辑价格信息
export const editPrice = data =>
  request({
    url: `/api/v1/estate/price/editPrice`,
    data
  });

// 查询开盘信息列表
export const getOpenInfoList = data =>
  request({
    url: `/api/v1/estate/price/getOpenInfoList`,
    data
  });

// 查询开盘信息详情
export const getOpenInfoDetail = data =>
  request({
    url: `/api/v1/estate/price/getOpenInfoDetail`,
    data
  });

// 创建开盘信息
export const createOpenInfo = data =>
  request({
    url: `/api/v1/estate/price/createOpenInfo`,
    data
  });

// 删除开盘信息
export const deleteOpenInfo = data =>
  request({
    url: `/api/v1/estate/price/deleteOpenInfo`,
    data
  });

// 编辑开盘信息
export const editOpenInfo = data =>
  request({
    url: `/api/v1/estate/price/editOpenInfo`,
    data
  });

// 根据楼盘id查询物业类型
export const getEstatePropertytypes = data =>
  request({
    url: `/api/v1/estate/price/getEstatePropertytypes`,
    data
  });

/*****************************楼盘评论*************************************/

// 创建评论
export const createComment = data =>
  request({
    url: `/api/v1/estate/comment/manage/${data.estateId}/create`,
    data
  });

// 获取评论
export const getCommentList = params =>
  request({
    url: `/api/v1/estate/comment/manage/${params.estateId}/list`,
    params,
    method: "GET"
  });

// 删除评论
export const deleteComment = data =>
  request({
    url: `/api/v1/estate/comment/manage/${data.id}/delete`,
    data
  });

// 动态列表
export const trendsList = params =>
  request({
    url: `/api/v1/platform/web/trends/platform/list`,
    method: "get",
    params
  });

// 发布动态
export const createTrends = data =>
  request({
    url: `/api/v1/platform/web/trends/platform/create`,
    method: "post",
    data
  });

// 删除动态
export const deleteTrends = params =>
  request({
    url: `/api/v1/platform/web/trends/delete`,
    method: "post",
    params
  });

// 分页查询[动态|问答]评论
export const trendsComment = params =>
  request({
    url: `/api/v1/platform/web/trends/comment/page`,
    method: "get",
    params
  });

// 删除评论/回复
export const deleteTrendsComment = params =>
  request({
    url: `/api/v1/platform/web/trends/comment/delete`,
    method: "post",
    params
  });

// 问答列表
export const questionList = params =>
  request({
    url: `/api/v1/platform/web/trends/question/page`,
    method: "get",
    params
  });

// 采纳
export const trendsAccepted = params =>
  request({
    url: `api/v1/platform/web/trends/accepted`,
    method: "post",
    params
  });

// 所属企业
export const enterpriseInfo = data =>
  request({
    url: `/api/v1/fyb/web/enterprise/manage/all/enterprise/info`,
    data,
    method: "post"
  });

// 查询户型列表
export const getHousetypeList = data =>
  request({
    url: `/api/v1/estate/housetype/list`,
    data,
    method: "post"
  });

// 编辑户型
export const housetypeUpdate = data =>
  request({
    url: `/api/v1/estate/housetype/update`,
    data,
    method: "post"
  });

// 删除户型
export const housetypeDelete = data =>
  request({
    url: `/api/v1/estate/housetype/delete`,
    data,
    method: "post"
  });

// 上下架
export const housetypeOn = data =>
  request({
    url: `/api/v1/estate/housetype/on/${data.id}`,
    data,
    method: "post"
  });

// 发布
export const housetypeDeploy = data =>
  request({
    url: `/api/v1/estate/housetype/deploy/${data.id}`,
    data,
    method: "post"
  });

// 推荐/取消推荐
export const housetypeTop = data =>
  request({
    url: `/api/v1/estate/housetype/top/${data.id}`,
    data,
    method: "post"
  });

// 编辑样板间
export const updateImages = data =>
  request({
    url: `/api/v1/estate/housetype/update/images`,
    data,
    method: "post"
  });

// 删除样板间
export const deleteImage = data =>
  request({
    url: `/api/v1/estate/housetype/delete/image/${data.id}`,
    data,
    method: "post"
  });

// 新增楼盘相册图片
export const addEstateAlbumImages = data =>
  request({
    url: `/api/v1/estate/image/add`,
    data
  });

// 删除楼盘相册图片
export const deleteEstateAlbumImage = data =>
  request({
    url: `/api/v1/estate/image/delete/${data.id}`,
    data
  });

// 获取楼盘相册图片
export const getEstateAlbumImages = data =>
  request({
    url: `/api/v1/estate/image/${data.estateId}/list`,
    data
  });

// 编辑楼盘相册图片
export const editEstateAlbumImages = data =>
  request({
    url: `/api/v1/estate/image/edit`,
    data
  });

// 创建楼盘视频
export const createEstateVideo = data =>
  request({
    url: `/api/v1/estate/videovr/estate/${data.estateId}/create/video`,
    data
  });

// 删除楼盘视频
export const delEstateVideo = data =>
  request({
    url: `/api/v1/estate/videovr/delete/${data.id}`,
    data
  });

// 获取楼盘视频列表
export const getEstateVideoList = data =>
  request({
    url: `/api/v1/estate/videovr/estate/${data.estateId}/video`,
    data
  });

//编辑新增优惠券
export const editEstateCoupon = data =>
  request({
    url: `/api/v1/fxb/fybestate/editEstateCoupon`,
    method: "POST",
    data
  });

//获取优惠券列表优惠券
export const getEstateCouponList = data =>
  request({
    url: `/api/v1/fxb/fybestate/getEstateCouponPage`,
    method: "POST",
    data
  });

//获取优惠券领取详情
export const getEstateCouponReceiveList = data =>
  request({
    url: `/api/v1/fxb/fybestate/getCouponRecievePage`,
    method: "POST",
    data
  });

//更新优惠券状态 上架/下架
export const updateEstateCouponStatus = data =>
  request({
    url: `/api/v1/fxb/fybestate/updateEstateCouponStatus`,
    method: "POST",
    data
  });

//核销优惠券
export const checkCoupon = data =>
  request({
    url: `/api/v1/fxb/fybestate/checkCoupon`,
    method: "POST",
    data
  });
