<script>
export default {
  functional: true,
  props: {
    render: {
      type: Function,
      required: true
    },
    scope: {
      type: Object,
      required: true
    },
    tag: {
      type: String,
      default: "div"
    }
  },
  render: (h, ctx) => {
    const { scope, render, tag } = ctx.props;
    const params = {
      row: scope.row,
      index: scope.$index
    };
    const VNode = render(params, h);
    return h(tag, [VNode], "");
  }
};
</script>
