var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "platform-box"
  }, [_vm.platformType === 1 ? _c('div', {
    staticClass: "platform-info"
  }, [_c('img', {
    staticClass: "default-logo",
    attrs: {
      "src": require("../../assets/logo.png")
    }
  })]) : _vm._e(), _vm.platformType === 2 ? _c('div', {
    staticClass: "platform-info"
  }, [_c('el-image', {
    staticClass: "logo",
    attrs: {
      "fit": "cover",
      "src": _vm.$estateInfo.estateLogo
    }
  }, [_c('i', {
    staticClass: "el-icon-picture",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  })]), _c('p', {
    staticClass: "text"
  }, [_vm._v(" " + _vm._s(_vm.$estateInfo.estateName) + " ")])], 1) : _vm._e(), _vm.platformType === 3 || _vm.platformType === 4 ? _c('div', {
    staticClass: "platform-info"
  }, [_c('el-image', {
    staticClass: "logo",
    attrs: {
      "fit": "cover",
      "src": _vm.$platformInfo.logo
    }
  }, [_c('i', {
    staticClass: "el-icon-picture",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  })]), _vm.$platformInfo.name ? _c('div', {
    staticClass: "text"
  }, [_c('p', [_vm._v(_vm._s(_vm.$platformInfo.name) + "-" + _vm._s(_vm.$platformInfo.applicationName))]), _vm.platformType == 4 ? _c('p', {
    staticClass: "desc"
  }, [_vm._v("分销平台")]) : _vm._e()]) : _vm._e()], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }